import { checkWindow, isAcceptedCookieLawGroup } from "@vfit/shared/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
var useTrackingHeroSliderConsumer = function() {
    var onTrack = function(s) {
        if (!isAcceptedCookieLawGroup("2") || !checkWindow()) return;
        var uxa = window === null || window === void 0 ? void 0 : window["_uxa"];
        if (!uxa) window["_uxa"] = uxa || [];
        try {
            LoggerInstance.log("Push uxa", {
                category: "homepage",
                name: "viewSlide".concat(s.id),
                type: "scroll"
            });
        // Resolve bug MOBILE
        // uxa?.push?.(['trackPageEvent', `viewSlide${s.id}`]);
        } catch (e) {
            LoggerInstance.log("Error uxa", e);
        }
    };
    return {
        onTrack: onTrack
    };
};
export { useTrackingHeroSliderConsumer };
