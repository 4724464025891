export var getFormatprops = function(elements) {
    return elements.map(function(el, index) {
        return {
            image: el === null || el === void 0 ? void 0 : el.image,
            frontLabel: el === null || el === void 0 ? void 0 : el.frontLabel,
            description: el === null || el === void 0 ? void 0 : el.descriptionHtml,
            title: el === null || el === void 0 ? void 0 : el.title,
            action: el === null || el === void 0 ? void 0 : el.action,
            key: index.toString()
        };
    });
};
