import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { ICardDirectionType, ICardStyleType } from "./stepperStateCards.models";
export var CARDS_STYLE = {
    MOBILE: {
        HIDDEN: {
            zIndex: 0,
            top: 54,
            opacity: 0,
            width: 237,
            height: 492
        },
        FIRST: {
            zIndex: 1,
            top: 66,
            opacity: 0.9,
            width: 267,
            height: 492
        },
        SECOND: {
            zIndex: 2,
            top: 78,
            opacity: 0.9,
            width: 291,
            height: 492
        },
        THIRD: {
            zIndex: 3,
            top: 90,
            width: 327,
            height: 492
        }
    },
    TABLET: {
        HIDDEN: {
            zIndex: 0,
            top: 54,
            opacity: 0,
            width: 337,
            height: 737
        },
        FIRST: {
            zIndex: 1,
            top: 66,
            opacity: 0.9,
            width: 386,
            height: 737
        },
        SECOND: {
            zIndex: 2,
            top: 78,
            opacity: 0.9,
            width: 410,
            height: 737
        },
        THIRD: {
            zIndex: 3,
            top: 90,
            width: 446,
            height: 737
        }
    },
    DESKTOP: {
        HIDDEN: {
            zIndex: 0,
            top: 109,
            opacity: 0,
            width: 420,
            height: 737
        },
        FIRST: {
            zIndex: 1,
            top: 121,
            opacity: 0.9,
            width: 450,
            height: 737
        },
        SECOND: {
            zIndex: 2,
            top: 133,
            opacity: 0.9,
            width: 480,
            height: 737
        },
        THIRD: {
            zIndex: 3,
            top: 145,
            width: 515,
            height: 737
        }
    }
};
var getCardStyle = function(isMobile, isTablet, isDektop) {
    var mainStyle = CARDS_STYLE.MOBILE;
    if (isMobile) mainStyle = CARDS_STYLE.MOBILE;
    if (isTablet) mainStyle = CARDS_STYLE.TABLET;
    if (isDektop) mainStyle = CARDS_STYLE.DESKTOP;
    return mainStyle;
};
var getDefaultCards = function(activeSlide, totalSlides, mainCardStyle) {
    var defCards = [];
    Array.from(Array(totalSlides)).forEach(function(el, index) {
        if (index < totalSlides - 3) {
            defCards.push({
                cardStyle: mainCardStyle.HIDDEN,
                cardStyleType: ICardStyleType.HIDDEN
            });
        } else if (index === totalSlides - 3) {
            defCards.push({
                cardStyle: mainCardStyle.FIRST,
                cardStyleType: ICardStyleType.FIRST
            });
        } else if (index === totalSlides - 2) {
            defCards.push({
                cardStyle: mainCardStyle.SECOND,
                cardStyleType: ICardStyleType.SECOND
            });
        } else if (index === totalSlides - 1) {
            defCards.push(_object_spread_props(_object_spread({}, activeSlide), {
                cardStyle: mainCardStyle.THIRD,
                cardStyleType: ICardStyleType.THIRD
            }));
        }
    });
    return defCards;
};
var goToNextCard = function(cards, allCards, activeSlide, mainCardStyle) {
    var allOrganizedCards = cards;
    // region DIFF FOR DINAMIC SLIDES
    var countChangedSlide = allCards.length - (allOrganizedCards.length + activeSlide - 1);
    if (countChangedSlide !== 0) {
        if (countChangedSlide > 0) {
            Array.from(Array(countChangedSlide)).forEach(function() {
                allOrganizedCards.push({
                    cardStyle: mainCardStyle.HIDDEN,
                    cardStyleType: ICardStyleType.HIDDEN
                });
            });
        } else {
            allOrganizedCards = allOrganizedCards.slice(0, countChangedSlide);
        }
    }
    // endregion
    // Remove first card
    var filteredSteps = allOrganizedCards.filter(function(stateStep, indexEl) {
        if (indexEl !== allOrganizedCards.length - 1) return stateStep;
        return null;
    });
    var returnLastSteps = function(indexFiltered, filteredStep) {
        if (indexFiltered === filteredSteps.length - 3) {
            return _object_spread_props(_object_spread({}, filteredStep), {
                cardStyleType: ICardStyleType.FIRST,
                cardStyle: mainCardStyle.FIRST
            });
        }
        if (indexFiltered === filteredSteps.length - 2) {
            return _object_spread_props(_object_spread({}, filteredStep), {
                cardStyleType: ICardStyleType.SECOND,
                cardStyle: mainCardStyle.SECOND
            });
        }
        if (indexFiltered === filteredSteps.length - 1) {
            return _object_spread_props(_object_spread({}, filteredStep), {
                component: allCards[activeSlide].component,
                cardStyleType: ICardStyleType.THIRD,
                cardStyle: mainCardStyle.THIRD
            });
        }
        return filteredStep;
    };
    return filteredSteps.map(function(filteredStep, indexFiltered) {
        if (filteredSteps.length > 3) {
            if (indexFiltered < filteredSteps.length - 3) {
                return _object_spread_props(_object_spread({}, filteredStep), {
                    cardStyleType: ICardStyleType.HIDDEN,
                    cardStyle: mainCardStyle.HIDDEN
                });
            }
            return returnLastSteps(indexFiltered, filteredStep);
        }
        return returnLastSteps(indexFiltered, filteredStep);
    });
};
var goToPrevCard = function(cards, allSlide, activeSlide, mainCardStyle) {
    var filteredCards = [];
    cards.reverse().forEach(function(card) {
        var cardStyleType = card.cardStyleType;
        if (cardStyleType === ICardStyleType.THIRD) {
            filteredCards.push(_object_spread_props(_object_spread({}, card), {
                component: undefined,
                cardStyleType: ICardStyleType.SECOND,
                cardStyle: mainCardStyle.SECOND
            }));
        } else if (cardStyleType === ICardStyleType.SECOND) {
            filteredCards.push(_object_spread_props(_object_spread({}, card), {
                component: undefined,
                cardStyleType: ICardStyleType.FIRST,
                cardStyle: mainCardStyle.FIRST
            }));
        } else if (cardStyleType === ICardStyleType.FIRST) {
            filteredCards.push(_object_spread_props(_object_spread({}, card), {
                component: undefined,
                cardStyleType: ICardStyleType.HIDDEN,
                cardStyle: mainCardStyle.HIDDEN
            }));
        } else {
            filteredCards.push(_object_spread_props(_object_spread({}, card), {
                component: undefined,
                cardStyleType: ICardStyleType.HIDDEN,
                cardStyle: mainCardStyle.HIDDEN
            }));
        }
    });
    filteredCards.unshift(_object_spread_props(_object_spread({}, allSlide[activeSlide]), {
        cardStyle: mainCardStyle.THIRD,
        cardStyleType: ICardStyleType.THIRD
    }));
    return filteredCards.reverse();
};
var getResizedSteps = function(cards, mainCardStyle) {
    return cards.map(function(card) {
        switch(card.cardStyleType){
            case ICardStyleType.HIDDEN:
                return _object_spread_props(_object_spread({}, card), {
                    cardStyle: mainCardStyle.HIDDEN
                });
            case ICardStyleType.THIRD:
                return _object_spread_props(_object_spread({}, card), {
                    cardStyle: mainCardStyle.THIRD
                });
            case ICardStyleType.SECOND:
                return _object_spread_props(_object_spread({}, card), {
                    cardStyle: mainCardStyle.SECOND
                });
            case ICardStyleType.FIRST:
                return _object_spread_props(_object_spread({}, card), {
                    cardStyle: mainCardStyle.FIRST
                });
            default:
                return card;
        }
    });
};
export var organizeSlide = function(direction, allSteps, stateSteps, currentSlideIndex, mainCardStyle) {
    if (mainCardStyle) {
        if (currentSlideIndex === 0) {
            return getDefaultCards(allSteps[currentSlideIndex], allSteps.length, mainCardStyle);
        }
        if (currentSlideIndex !== allSteps.length) {
            if (direction === ICardDirectionType.NEXT) {
                return goToNextCard(stateSteps, allSteps, currentSlideIndex, mainCardStyle);
            }
            if (direction === ICardDirectionType.PREV) {
                return goToPrevCard(stateSteps, allSteps, currentSlideIndex, mainCardStyle);
            }
            return [];
        }
        return [];
    }
    return [];
};
export { getCardStyle, getDefaultCards, goToNextCard, goToPrevCard, getResizedSteps };
