export var otpFormSchema = {
    otp: {
        required: {
            value: true,
            message: "Campo obbligatorio"
        },
        pattern: {
            value: /^[0-9]{6}$/,
            message: "Campo obbligatorio"
        }
    }
};
