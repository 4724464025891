import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { API } from "@vfit/shared/data-access";
import { LoggerInstance, handleUseQuery } from "@vfit/shared/providers";
import { dxlConsumerCloudClient } from "libs/shared/providers/src/lib/fetcher";
import { errorMock } from "../../utils/utils";
/**
 * This method is used to retrieve data for enrichment services: it retrieves the msisdn if the customer has one associated.
 * This in used in the customHookFlow hook
 * Method: GET
 * @returns
 */ var identifyService = function(customOptions) {
    return dxlConsumerCloudClient.get(API.DXL_IDENTIFY, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the identifyService
 * @param customOptions
 * @returns
 */ export var useIdentify = function(customOptions) {
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "identify";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var result = identifyService(customOptions);
            return result;
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions.enabled : false,
            onSuccess: function(data) {
                localStorage.setItem("identify", JSON.stringify(data));
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - identify", error);
                localStorage.setItem("identify", JSON.stringify(errorMock("identify")));
            }
        })
    };
    return handleUseQuery(options);
};
