/**
 * Method to return the faq widget
 * @param widget
 */ var formatFaqProps = function(widget) {
    return {
        topText: (widget === null || widget === void 0 ? void 0 : widget.title) || "",
        faq: {
            divider: widget === null || widget === void 0 ? void 0 : widget.divider,
            elements: (widget === null || widget === void 0 ? void 0 : widget.elements) || [],
            showMore: widget === null || widget === void 0 ? void 0 : widget.customAction
        }
    };
};
export { formatFaqProps };
