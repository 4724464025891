import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var organizeVoucherDiscount = function(cmsData, voucher) {
    var ref, ref1, ref2, ref3;
    return {
        vouchersdiscount: _object_spread_props(_object_spread({}, cmsData === null || cmsData === void 0 ? void 0 : cmsData.vouchersdiscount), {
            appliedVoucher: _object_spread_props(_object_spread({}, cmsData === null || cmsData === void 0 ? void 0 : (ref = cmsData.vouchersdiscount) === null || ref === void 0 ? void 0 : ref.appliedVoucher), {
                subtitle: cmsData === null || cmsData === void 0 ? void 0 : (ref1 = cmsData.vouchersdiscount) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.appliedVoucher) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.subtitle) === null || ref3 === void 0 ? void 0 : ref3.replace("$VOUCHER", voucher || "")
            })
        })
    };
};
