import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { awsConsumerClient, LoggerInstance } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { useMutation } from "react-query";
import { retrieveUserAgent } from "@vfit/consumer/data-access";
var userAppChallenge = function(payload, deviceId) {
    return awsConsumerClient.post("".concat(API.DXL_USER_APP_V1, "/").concat(payload.username, "/challenge"), _object_spread({}, payload), {
        headers: {
            "X-Device-UserAgent": retrieveUserAgent(),
            "X-Device-Id": deviceId,
            "vf-a-appconsumermfa": sessionStorage.getItem("dxlJwtMfa")
        }
    });
};
export var useUserAppChallenge = function() {
    return useMutation("userAppChallenge", function(param) {
        var payload = param.payload, deviceId = param.deviceId;
        return userAppChallenge(_object_spread({}, payload), deviceId);
    }, {
        onSuccess: function(data) {
            LoggerInstance.error("SUCCESS - userAppChallenge", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - userAppChallenge", error);
        }
    });
};
