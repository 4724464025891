import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n\n  .swiper {\n    margin-left: inherit;\n  }\n\n  .swiper-wrapper {\n    height: inherit;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 45px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin-bottom: 120px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  div {\n    padding: 0 0 32px;\n    margin-bottom: 0;\n    margin-top: 0;\n    margin-left: 24px;\n    margin-right: 24px;\n\n    p {\n      margin: 0;\n    }\n\n    ",
        "\n    b {\n      ",
        "\n    }\n\n    @media (min-width: ",
        ") {\n      padding: 0 0 48px;\n    }\n\n    @media (min-width: ",
        ") {\n      padding: 0 0 48px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, getTypography } from "@vfit/shared/themes";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-1628398a-0"
})(_templateObject());
export var ProductListContainer = styled.div.withConfig({
    componentId: "sc-1628398a-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.bigDesktop);
export var Title = styled.div.withConfig({
    componentId: "sc-1628398a-2"
})(_templateObject2(), getTypography("h4.light"), getTypography("h4.extrabold"), breakpoints.tablet, breakpoints.desktop);
