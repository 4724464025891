import { useQueryClient } from "react-query";
import { getCmsLabel } from "../../utils/cms";
export var useOrganizeEnrichedOtpModal = function() {
    var queryClient = useQueryClient();
    var LOGIN_ERROR_DATA = {
        urlToRedirect: getCmsLabel(queryClient, "login", "errorUrlToRedirect") || "https://www.vodafone.it/area-utente/appmanager/fai-da-te/Common?_nfpb=true&_pageLabel=P12800731511384768997354",
        modalTitle: getCmsLabel(queryClient, "login", "errorModalTitle") || "Ops",
        modalDescription: getCmsLabel(queryClient, "login", "errorModalDescription") || "Il servizio non \xe8 al momento disponibile, riprova in seguito"
    };
    var ENRICHED_OTP_DATA = {
        title: getCmsLabel(queryClient, "otpEnriched", "title") || "Verifica identit\xe0",
        description: getCmsLabel(queryClient, "otpEnriched", "description") || "Per confermare la tua identit\xe0, inserisci il codice a 6 cifre che abbiamo inviato al tuo numero di telefono.",
        inputLabel: getCmsLabel(queryClient, "otpEnriched", "inputLabel") || "Inserisci il codice ricevuto",
        newCodeLabel: getCmsLabel(queryClient, "otpEnriched", "newCodeLabel") || "Non hai ricevuto il codice?",
        newCodeAnchor: getCmsLabel(queryClient, "otpEnriched", "newCodeAnchor") || "Richiedi nuovo codice",
        newCodeDisclaimer: getCmsLabel(queryClient, "otpEnriched", "newCodeDisclaimer") || "Ti abbiamo inviato un nuovo codice, ricontrolla tra qualche istante",
        errorDisclaimer: getCmsLabel(queryClient, "otpEnriched", "errorDisclaimer") || "Il codice inserito non \xe8 corretto, prova ad inserirlo nuovamente"
    };
    return {
        login: LOGIN_ERROR_DATA,
        enrichedOtp: ENRICHED_OTP_DATA
    };
};
