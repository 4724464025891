import _define_property from "@swc/helpers/src/_define_property.mjs";
import { ICMSWidgetType } from "./widgets.models";
import TopHero from "./TopHero/topHero";
import SearchNation from "./SearchNation/searchNation";
import FaqWidget from "./Faq/faq";
import CardsInfoProduct from "./CardsInfoProduct/cardsInfoProduct";
import Text from "./Text/text";
import ColumnItemsText from "./ColumnItemsText/columnItemsText";
import ConnectionCards from "./ConnectionCards/connectionCards";
import SmartPassportWidget from "./SmartPassport/SmartPassport";
import AppQR from "./AppQR/AppQR";
import SliderProductsHub from "./SliderProductsHub/sliderProductsHub";
import SearchingOffersEu from "./SearchingOffersEu/searchingOffersEu";
import OperatorPlans from "./OperatorPlans/operatorPlans";
import WorldOffers from "./WorldOffers/worldOffers";
import SkeletonLoading from "./SkeletonLoading/skeletonLoading";
import CustomText from "./CustomText/customText";
import Anchors from "./Anchors/anchors";
import ModalOperatorsPlan from "./ModalOperatorPlans/modalOperatorPlans";
var _obj;
export var WidgetComponents = (_obj = {}, _define_property(_obj, ICMSWidgetType.APP_QR, AppQR), _define_property(_obj, ICMSWidgetType.ANCHORS, Anchors), _define_property(_obj, ICMSWidgetType.TOP_HERO, TopHero), _define_property(_obj, ICMSWidgetType.SEARCH_NATION, SearchNation), _define_property(_obj, ICMSWidgetType.FAQ, FaqWidget), _define_property(_obj, ICMSWidgetType.SLIDER_ICON_TITLE_DESCRIPTION, CardsInfoProduct), _define_property(_obj, ICMSWidgetType.TEXT, Text), _define_property(_obj, ICMSWidgetType.COLUMN_ITEMS, ColumnItemsText), _define_property(_obj, ICMSWidgetType.SLIDER_IMAGE_TITLE_DESCRIPTION, ConnectionCards), _define_property(_obj, ICMSWidgetType.SMART_PASSPORT, SmartPassportWidget), _define_property(_obj, ICMSWidgetType.SLIDER_PRODUCTS_HUB_BUSINESS, SliderProductsHub), _define_property(_obj, ICMSWidgetType.SEARCHING_OFFERS_EU, SearchingOffersEu), _define_property(_obj, ICMSWidgetType.OPERATOR_PLANS_MODAL, ModalOperatorsPlan), _define_property(_obj, ICMSWidgetType.OPERATOR_PLANS, OperatorPlans), _define_property(_obj, ICMSWidgetType.OFFERS_WORLD, WorldOffers), _define_property(_obj, ICMSWidgetType.SKELETON_LOADER, SkeletonLoading), _define_property(_obj, ICMSWidgetType.CUSTOM_TEXT, CustomText), _obj);
