import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  align-items: center;\n  width: 600px;\n  padding: 0 16px;\n\n  p {\n    margin: 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  text-align: center;\n  ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: 150px;\n  height: 150px;\n  object-fit: cover;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  height: 120px;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  text-align: center;\n  ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 84px;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import { pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var OverlayContainer = styled.div.withConfig({
    componentId: "sc-e8787787-0"
})(_templateObject());
export var Title = styled.div.withConfig({
    componentId: "sc-e8787787-1"
})(_templateObject1(), pxToCssFont(40, 48));
export var QRCode = styled.div.withConfig({
    componentId: "sc-e8787787-2"
})(_templateObject2());
export var Image = styled.div.withConfig({
    componentId: "sc-e8787787-3"
})(_templateObject3());
export var Description = styled.p.withConfig({
    componentId: "sc-e8787787-4"
})(_templateObject4(), pxToCssFont(18, 26));
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-e8787787-5"
})(_templateObject5());
