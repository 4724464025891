import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding: ",
        ";\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    padding: ",
        ";\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  margin-bottom: 8px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 32px;\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin: 0 auto;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints, getTypography, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Container = styled.div.withConfig({
    componentId: "sc-e3d31552-0"
})(_templateObject(), pxToRem(120, 35), breakpoints.tablet, pxToRem(80, 120));
export var Title = styled.h1.withConfig({
    componentId: "sc-e3d31552-1"
})(_templateObject1(), getTypography("h4.bold"));
export var Description = styled.h4.withConfig({
    componentId: "sc-e3d31552-2"
})(_templateObject2(), getTypography("h6.light"));
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-e3d31552-3"
})(_templateObject3());
