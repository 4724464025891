import Bowser from "bowser";
export var APP_VERSION = "13.11.0";
var getScreenScale = function() {
    return window.devicePixelRatio || 1;
};
export var retrieveUserAgent = function() {
    var appId = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "MyVodafoneWeb";
    var bowser = Bowser.getParser(navigator.userAgent);
    var os = bowser.getOS();
    var platform = bowser.getPlatform();
    var isTablet = bowser.getPlatformType() === "tablet";
    return "".concat(appId, "/").concat(os.name, "/").concat(isTablet ? "t" : "s", "/").concat(os.version, "/").concat(APP_VERSION, "/").concat(getScreenScale(), '/store/"').concat(platform.vendor, '"/"').concat((platform === null || platform === void 0 ? void 0 : platform.model) || "desktop", '"');
};
