import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 16px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n  gap: 24px;\n  padding-top: 16px;\n\n  .textInput {\n    width: 100%;\n  }\n\n  @media (min-width: ",
        ") {\n    .textInput {\n      width: 400px;\n    }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-self: flex-start;\n  margin: 0 0 24px;\n  font-family: ",
        ";\n  ",
        ";\n  text-align: start;\n\n  @media (min-width: ",
        ") {\n    margin: 0 0 48px;\n    ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-family: ",
        ";\n  ",
        ";\n  text-align: start;\n\n  @media (min-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  text-align: center;\n  font-family: ",
        ";\n  font-weight: 700;\n  ",
        ";\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  text-align: center;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        ";\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  text-align: center;\n  font-family: ",
        ";\n  ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n\n  div {\n    width: 100%;\n  }\n\n  button {\n    border-radius: 4px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 280px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
export var ImageDiv = styled.div.withConfig({
    componentId: "sc-19e38ecf-0"
})(_templateObject());
export var InputDiv = styled.div.withConfig({
    componentId: "sc-19e38ecf-1"
})(_templateObject1(), breakpoints.tablet);
export var ModalTitle = styled.p.withConfig({
    componentId: "sc-19e38ecf-2"
})(_templateObject2(), fonts.regular, pxToCssFont(20, 30), breakpoints.tablet, pxToCssFont(40, 48));
export var ModalDescription = styled.p.withConfig({
    componentId: "sc-19e38ecf-3"
})(_templateObject3(), fonts.regular, pxToCssFont(16, 22), breakpoints.tablet, pxToCssFont(18, 24));
export var Title = styled.p.withConfig({
    componentId: "sc-19e38ecf-4"
})(_templateObject4(), fonts.regular, pxToCssFont(20, 24));
export var Subtitle = styled.p.withConfig({
    componentId: "sc-19e38ecf-5"
})(_templateObject5(), fonts.regular, pxToCssFont(18, 24));
export var Description = styled.p.withConfig({
    componentId: "sc-19e38ecf-6"
})(_templateObject6(), fonts.regular, pxToCssFont(14, 16));
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-19e38ecf-7"
})(_templateObject7(), breakpoints.tablet);
