export var ICMSWidgetType;
(function(ICMSWidgetType) {
    ICMSWidgetType["SEARCH_NATION"] = "SEARCH_NATION";
    ICMSWidgetType["TOP_HERO"] = "TOP_HERO";
    ICMSWidgetType["FAQ"] = "FAQ";
    ICMSWidgetType["SMART_PASSPORT"] = "SMART_PASSPORT";
    ICMSWidgetType["SLIDER_ICON_TITLE_DESCRIPTION"] = "SLIDER_ICON_TITLE_DESCRIPTION";
    ICMSWidgetType["TEXT"] = "TEXT";
    ICMSWidgetType["OPERATOR_PLANS_MODAL"] = "OPERATOR_PLANS_MODAL";
    ICMSWidgetType["SEARCHING_OFFERS_EU"] = "SEARCHING_OFFERS_EU";
    ICMSWidgetType["COLUMN_ITEMS"] = "COLUMN_ITEMS";
    ICMSWidgetType["SPLITTED_IMAGE_TEXT"] = "SPLITTED_IMAGE_TEXT";
    ICMSWidgetType["SLIDER_LARGE_SMALL"] = "SLIDER_LARGE_SMALL";
    ICMSWidgetType["SLIDER_PRODUCTS_HUB_BUSINESS"] = "SLIDER_PRODUCTS_HUB_BUSINESS";
    ICMSWidgetType["PRODUCT_HERO_BANNER"] = "PRODUCT_HERO_BANNER";
    ICMSWidgetType["APP_QR"] = "APP_QR";
    ICMSWidgetType["SKELETON_LOADER"] = "SKELETON_LOADER";
    ICMSWidgetType["SHOPPING_CART_TECHNOLOGIES"] = "SHOPPING_CART_TECHNOLOGIES";
    ICMSWidgetType["SLIDER_IMAGE_TITLE_DESCRIPTION"] = "SLIDER_IMAGE_TITLE_DESCRIPTION";
    ICMSWidgetType["SLIDER_DEFAULT"] = "SLIDER_DEFAULT";
    ICMSWidgetType["TABS"] = "TABS";
    ICMSWidgetType["OFFERS_WORLD"] = "OFFERS_WORLD";
    ICMSWidgetType["CUSTOM_TEXT"] = "CUSTOM_TEXT";
    ICMSWidgetType["OPERATOR_PLANS"] = "OPERATOR_PLANS";
    ICMSWidgetType["ANCHORS"] = "ANCHORS";
    ICMSWidgetType["SLIDER_ADDON"] = "SLIDER_ADDON";
    ICMSWidgetType["HUB_DEVICE"] = "HUB_DEVICE";
    ICMSWidgetType["DEVICE_HERO_BANNER"] = "DEVICE_HERO_BANNER";
    ICMSWidgetType["SPLITTED_TABLE_TEXT"] = "SPLITTED_TABLE_TEXT";
    ICMSWidgetType["HUB_DS"] = "HUB_DS";
    ICMSWidgetType["DS_HERO_BANNER"] = "DS_HERO_BANNER";
    ICMSWidgetType["GUIDED_SELLING_TOOL"] = "GUIDED_SELLING_TOOL";
    ICMSWidgetType["SLIDER_PRODUCT_DS_PDP"] = "SLIDER_PRODUCT_DS_PDP";
    ICMSWidgetType["EDITORIAL_CARD"] = "EDITORIAL_CARD";
    ICMSWidgetType["SLOT_CALENDAR"] = "SLOT_CALENDAR";
    ICMSWidgetType["EDITORIAL_CARD_SLIDER"] = "EDITORIAL_CARD_SLIDER";
    ICMSWidgetType["SIM_ACTIVATION_OTP"] = "SIM_ACTIVATION_OTP";
    ICMSWidgetType["OFFER_MAP_SHOPPING_CART"] = "OFFER_MAP_SHOPPING_CART";
    ICMSWidgetType["FULL_BANNER"] = "FULL_BANNER";
    ICMSWidgetType["LANDING_ESTERO_POPUP"] = "LANDING_ESTERO_POPUP";
})(ICMSWidgetType || (ICMSWidgetType = {}));
