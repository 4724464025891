import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding-bottom: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
var WidgetContainer = styled.div.withConfig({
    componentId: "sc-d6041365-0"
})(_templateObject(), function(param) {
    var isLastWidget = param.isLastWidget;
    return isLastWidget ? "109px" : 0;
});
export { WidgetContainer };
