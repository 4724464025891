import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { awsConsumerClient, LoggerInstance } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { useMutation } from "react-query";
import { retrieveUserAgent } from "@vfit/consumer/data-access";
var validateMFAChallengeAndLogin = function(payload, deviceId) {
    return awsConsumerClient.post("".concat(API.DXL_VALIDATE_MFA_CHALLENGE_AND_LOGIN_V2), _object_spread({}, payload), {
        headers: {
            "X-Device-UserAgent": retrieveUserAgent(),
            "X-Device-Id": deviceId,
            "vf-a-appconsumermfa": sessionStorage.getItem("dxlJwtMfa")
        }
    });
};
export var useValidateMFAChallengeAndLogin = function() {
    return useMutation("useValidateMFAChallengeAndLogin", function(param) {
        var payload = param.payload, deviceId = param.deviceId;
        return validateMFAChallengeAndLogin(_object_spread({}, payload), deviceId);
    }, {
        onSuccess: function(data) {
            LoggerInstance.error("SUCCESS - useValidateMFAChallengeAndLogin", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - useValidateMFAChallengeAndLogin", error);
        }
    });
};
