import { awsConsumerClient, LoggerInstance } from "@vfit/shared/providers";
import { API, errorMock } from "@vfit/shared/data-access";
import { useMutation, useQueryClient } from "react-query";
import { retrieveUserAgent } from "@vfit/consumer/data-access";
import { retrieveRegisterPayload } from "./device.utils";
var registerDevice = function(payload) {
    return awsConsumerClient.post("".concat(API.DXL_REGISTER_DEVICE), payload, {
        headers: {
            "Content-Type": "application/json",
            "x-device-useragent": retrieveUserAgent()
        }
    });
};
export var useRegisterDevice = function() {
    var queryClient = useQueryClient();
    var payload = retrieveRegisterPayload();
    return useMutation("registerDevice", function() {
        return registerDevice(payload);
    }, {
        onSuccess: function(data) {
            queryClient.setQueryData("registerDevice", data);
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - registerDevice", error);
            queryClient.setQueryData("registerDevice", errorMock("registerDevice"));
        }
    });
};
