import { useForm } from "react-hook-form";
import { useState } from "react";
export var useOtp = function() {
    var ref = useState(false), hasSentNewCode = ref[0], setHasSentNewCode = ref[1];
    var ref1 = useForm({
        mode: "onSubmit"
    }), reset = ref1.reset, register = ref1.register, getValues = ref1.getValues, watch = ref1.watch, errors = ref1.formState.errors;
    var otpInserted = watch("otp");
    return {
        reset: reset,
        register: register,
        getValues: getValues,
        watch: watch,
        errors: errors,
        hasSentNewCode: hasSentNewCode,
        otpInserted: otpInserted,
        setHasSentNewCode: setHasSentNewCode
    };
};
