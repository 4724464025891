import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-top: ",
        "; \n  display: flex;\n  flex-direction: column;\n  // border: 2px solid red;\n  // border-radius: ",
        ";\n  align-items: center;\n  height: ",
        ";\n\n  @media (min-width: ",
        ") {\n    margin-top:  ",
        "; \n  }\n  @media (min-width: ",
        ") {\n    margin-top:  ",
        "; \n  }\n\n  h1 {\n    ",
        "\n    font-family: ",
        "\n    font-weight: 400;\n    margin-bottom: 0px;\n  }\n\n  p {\n    ",
        "\n    font-family: ",
        "\n    text-align: center;\n    margin: ",
        "; \n  }\n\n  span\n  {\n    font-family: ",
        "\n    ",
        "\n    font-weight: 800;\n    text-align: center;\n\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: auto;\n  width: ",
        ";\n  margin-top: ",
        ";\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: ",
        ";\n  height: ",
        ";\n  padding: ",
        ";\n  margin: ",
        ";\n  border-radius: ",
        ";\n  border: 1px solid #bebebe;\n\n  @media (max-width: ",
        ") {\n    width: 90%;\n    height: auto;\n    margin: ",
        ";\n  }\n\n  h1 {\n    ",
        "\n    font-family: ",
        ";\n    font-weight: 700;\n    margin-left: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  text-align: center;\n\n  p:nth-child(1) {\n    ",
        "\n    font-family: ",
        ";\n    text-decoration: line-through;\n    font-weight: 400;\n    width: ",
        ";\n    height: ",
        ";\n    margin: ",
        ";\n  }\n  p:nth-child(3) {\n    ",
        "\n    font-family: ",
        ";\n    font-weight: 800;\n    width: ",
        ";\n    height: ",
        ";\n    margin: ",
        ";\n  }\n\n  p:nth-child(2) {\n    ",
        "\n    font-family: ",
        ";\n    font-weight: 800;\n    width: ",
        ";\n    height: ",
        ";\n    margin: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
import { breakpoints, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Container = styled.div.withConfig({
    componentId: "sc-2d0a2195-0"
})(_templateObject(), pxToRem(150), pxToRem(20), pxToRem(389), breakpoints.tablet, pxToRem(100), breakpoints.desktop, pxToRem(20), pxToCssFont(36, 45), fonts.exbold, pxToCssFont(18, 24), fonts.regular, pxToRem(15), fonts.vodafone, pxToCssFont(18, 24));
export var ImageStyle = styled.img.withConfig({
    componentId: "sc-2d0a2195-1"
})(_templateObject1(), pxToRem(50), pxToRem(20));
export var DiscountAppliedBox = styled.div.withConfig({
    componentId: "sc-2d0a2195-2"
})(_templateObject2(), pxToRem(400), pxToRem(112), pxToRem(15), pxToRem(5, 20, 50, 20), pxToRem(5), breakpoints.tablet, pxToRem(0, 15, 0, 15), pxToCssFont(18, 24), fonts.vodafone, pxToRem(9));
export var PriceDiscount = styled.div.withConfig({
    componentId: "sc-2d0a2195-3"
})(_templateObject3(), pxToCssFont(22, 32), fonts.vodafone, pxToRem(80), pxToRem(32), pxToRem(15, 0, 15, 0), pxToCssFont(28, 36), fonts.exbold, pxToRem(80), pxToRem(36), pxToRem(11, 0, 10, 0), pxToCssFont(30, 48), fonts.exbold, pxToRem(107), pxToRem(48), pxToRem(5, 0, 10, 0));
