import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  padding: ",
        ";\n  width: 72vw;\n  height: 50vh;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { pxToRem } from "@vfit/shared/themes";
var LoadingContainer = styled.div.withConfig({
    componentId: "sc-b32f0022-0"
})(_templateObject(), pxToRem(60, 60));
export { LoadingContainer };
