import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n\n  ul {\n    display: flex;\n    flex-direction: column;\n    align-content: space-around;\n    width: 100%;\n    align-item: center;\n    justify-content: space-between;\n    list-style-type: none;\n    margin: 0;\n    border-bottom: 1px solid ",
        ";\n    padding: 20px;\n  }\n\n  ul li:first-child {\n    width: 100%;\n    text-align: center;\n    font-family: ",
        ";\n    font-size: 28px;\n  }\n\n  svg {\n    width: 28px;\n    height: 25px;\n  }\n\n  @media (min-width: ",
        ") {\n    ul {\n      display: flex;\n      flex-direction: row;\n      font-family: ",
        ";\n      font-size: 28px;\n    }\n\n    ul li:first-child {\n      width: 25%;\n      text-align: left;\n    }\n\n    ul li {\n      width: 15%;\n      text-align: center;\n    }\n\n    ul span {\n      display: none;\n    }\n\n    svg {\n      width: 35px;\n      height: 30px;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  background-color: ",
        ";\n  border-top-left-radius: 20px;\n  border-top-right-radius: 20px;\n\n  li {\n    font-family: ",
        " !important;\n    font-size: 18px !important;\n    line-height: 24px;\n    font-weight: 400;\n    color: ",
        ";\n    text-transform: uppercase;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n  font-size: 18px;\n  font-family: ",
        ";\n  font-weight: 400;\n  min-width: 35px;\n\n  span {\n    margin: auto 0;\n  }\n\n  @media (min-width: ",
        ") {\n    display: block;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts } from "@vfit/shared/themes";
export var TableContainer = styled.div.withConfig({
    componentId: "sc-b18d0c9d-0"
})(_templateObject(), colors.$ebebeb, colors.$ebebeb, fonts.exbold, breakpoints.tablet, fonts.exbold);
export var TableHeader = styled.ul.withConfig({
    componentId: "sc-b18d0c9d-1"
})(_templateObject1(), colors.$f2f2f2, fonts.regular, colors.$262626);
export var ListContainer = styled.li.withConfig({
    componentId: "sc-b18d0c9d-2"
})(_templateObject2(), fonts.regular, breakpoints.tablet);
