import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  border-top: 1px solid ",
        ";\n\n  h1 {\n    font-family: ",
        ";\n    ",
        ";\n    color: ",
        ";\n  }\n\n  p {\n    font-family: ",
        ";\n    ",
        ";\n    color: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    flex: 1 1 auto;\n  }\n\n  @media (min-width: ",
        ") {\n    h1 {\n      ",
        ";\n    }\n\n    p {\n      ",
        ";\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  :last-child {\n    border-right: none;\n  }\n\n  padding: 30px 0;\n  text-align: center;\n\n  h3 {\n    margin: auto;\n    text-transform: uppercase;\n    color: ",
        ";\n    ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    padding: 0 30px;\n    margin: 40px 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  p {\n    ",
        ";\n    color: ",
        ";\n    font-family: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  gap: 20px;\n\n  h1 {\n    font-family: ",
        ";\n    ",
        ";\n    color: ",
        ";\n  }\n\n  p {\n    font-family: ",
        ";\n    ",
        ";\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: 40px;\n\n  h1 {\n    font-family: ",
        ";\n    ",
        ";\n    color: ",
        ";\n  }\n\n  p {\n    font-family: ",
        ";\n    ",
        ";\n    color: ",
        ";\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    flex: 1 1 auto;\n  }\n\n  @media (min-width: ",
        ") {\n    h1 {\n      ",
        ";\n    }\n\n    p {\n      ",
        ";\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin: 24px;\n\n  @media (min-width: ",
        ") {\n    margin: 32px;\n  }\n\n  @media (min-width: ",
        ") {\n    margin: 80px 80px 48px;\n    padding: unset;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  border-radius: 20px;\n  border: 1px solid ",
        ";\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var SecondRow = styled.div.withConfig({
    componentId: "sc-6f24adfa-0"
})(_templateObject(), colors.$bebebe, fonts.exbold, pxToCssFont(28, 36), colors.$262626, fonts.regular, pxToCssFont(20, 28), colors.$262626, breakpoints.tablet, breakpoints.bigDesktop, pxToCssFont(36, 48), pxToCssFont(22, 32));
export var OfferItem = styled.div.withConfig({
    componentId: "sc-6f24adfa-1"
})(_templateObject1(), colors.$e60000, pxToCssFont(22, 32), breakpoints.bigDesktop);
export var CountriesFlagsContainer = styled.div.withConfig({
    componentId: "sc-6f24adfa-2"
})(_templateObject2(), pxToCssFont(14, 18), colors.$7e7e7e, fonts.regular);
export var CountriesFlags = styled.div.withConfig({
    componentId: "sc-6f24adfa-3"
})(_templateObject3(), fonts.exbold, pxToCssFont(36, 48), colors.$262626, fonts.regular, pxToCssFont(22, 32), colors.$262626);
export var OfferItemDetails = styled.div.withConfig({
    componentId: "sc-6f24adfa-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(28, 36), colors.$262626, fonts.regular, pxToCssFont(20, 28), colors.$262626, breakpoints.tablet, breakpoints.bigDesktop, pxToCssFont(36, 48), pxToCssFont(22, 32));
export var SelectContainer = styled.div.withConfig({
    componentId: "sc-6f24adfa-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.bigDesktop);
export var SelectOfferContainer = styled.div.withConfig({
    componentId: "sc-6f24adfa-6"
})(_templateObject6(), colors.$bebebe);
