import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, awsConsumerClient, handleEnv } from "@vfit/shared/providers";
import { errorMock, MOCK_DXL_MFA_TOKEN, retrieveUserAgent } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
var loginByNetworkEnrichment = function(deviceId) {
    return awsConsumerClient.post(API.LOGIN_ENRICHMENT_V2, undefined, {
        headers: {
            "X-Device-UserAgent": retrieveUserAgent(),
            "X-Device-Id": deviceId
        }
    });
};
export var usePostEnrichmentV2 = function() {
    var queryClient = useQueryClient();
    return useMutation("loginEnrichmentV2", function(deviceId) {
        sessionStorage.removeItem("dxlJwt");
        return loginByNetworkEnrichment(deviceId);
    }, {
        retry: 0,
        onSuccess: function(data) {
            queryClient.setQueryData("loginEnriched", data);
            localStorage.setItem("loginEnriched", JSON.stringify(data));
        },
        onError: function(error) {
            var isDevLocal = handleEnv("NODE_ENV") === "LOCAL" || handleEnv("NODE_ENV") === "development";
            var jwtMFA = error.headers.get("vf-a-appconsumermfa");
            if (jwtMFA || isDevLocal) {
                sessionStorage.setItem("dxlJwtMfa", isDevLocal ? MOCK_DXL_MFA_TOKEN : jwtMFA);
            }
            LoggerInstance.error("ERROR - loginEnriched: ", error);
            queryClient.setQueryData("loginEnriched", errorMock("loginEnriched"));
        }
    });
};
