import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  min-height: 100vh;\n  z-index: -10;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 24px;\n  padding: 24px 16px;\n  animation: 1s slidein;\n\n  @keyframes slidein {\n    0% {\n        margin-top: 100%;\n        opacity: 0;\n      }\n    \n    100% {\n        margin-top: 0%;\n        opacity: 1;\n      }\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  z-index: 0;\n  width: 100%;\n  gap: 16px;\n  padding: 16px;\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 20%);\n  border-radius: 6px;\n  background: ",
        ";\n\n  @media (min-width: ",
        ") {\n    width: 600px;\n    padding: 16px 24px 32px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 1px;\n  background: #d9d9d9;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  margin: 0 0 24px;\n  font-family: ",
        ";\n  ",
        ";\n  text-align: center;\n\n  @media (min-width: ",
        ") {\n    margin: 0 0 48px;\n    ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n\n  div {\n    width: 100%;\n  }\n\n  button {\n    border-radius: 4px;\n  }\n\n  @media (min-width: ",
        ") {\n    width: 280px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
export var BackgroundDiv = styled.div.withConfig({
    componentId: "sc-ef192415-0"
})(_templateObject());
export var Container = styled.div.withConfig({
    componentId: "sc-ef192415-1"
})(_templateObject1());
export var CardContainer = styled.div.withConfig({
    componentId: "sc-ef192415-2"
})(_templateObject2(), colors.$ffffff, breakpoints.tablet);
export var Divider = styled.div.withConfig({
    componentId: "sc-ef192415-3"
})(_templateObject3());
export var ModalTitle = styled.p.withConfig({
    componentId: "sc-ef192415-4"
})(_templateObject4(), fonts.regular, pxToCssFont(20, 30), breakpoints.tablet, pxToCssFont(40, 48));
export var ModalButton = styled.div.withConfig({
    componentId: "sc-ef192415-5"
})(_templateObject5(), breakpoints.tablet);
