import { CMS_CONFIG, useCmsConfig } from "@vfit/consumer/data-access";
import { API, PAGES } from "@vfit/shared/data-access";
import { useContext } from "react";
import { useQueryClient } from "react-query";
import { NationContext } from "../context/nation/nation.context";
import { activationManager, loginManager, mfaEnrichedManager } from "../providers";
import { useUserContext } from "../context/user/user.context";
import { getWidgetsForLoggedAndAnonymous, organizeWidgets } from "./templates.utils";
var useConsumerAbroad = function(aemData, isLoading) {
    var ref, ref1, ref2;
    var queryClient = useQueryClient();
    var ref3 = useUserContext(), isLogged = ref3.isLogged, isLegacy = ref3.isLegacy, activable = ref3.activable, items = ref3.items, isEnriched = ref3.isEnriched, activatedOffers = ref3.activatedOffers;
    var activableProducts = activable === null || activable === void 0 ? void 0 : (ref = activable.data) === null || ref === void 0 ? void 0 : (ref1 = ref.sections) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[0]) === null || ref2 === void 0 ? void 0 : ref2.products;
    var nation = useContext(NationContext).nation;
    var consumerOffersCSV = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER_ABROAD], API.CMS_CTO_GET_OFFERS_FILTER);
    var widgetsForLoggedAndAnonymous = getWidgetsForLoggedAndAnonymous(aemData, isLogged, items === null || items === void 0 ? void 0 : items.data, isLoading);
    var onActiveProduct = function(offerId) {
        var productNotInCartButActivable = {
            id: {
                productOfferingId: offerId
            }
        };
        var product = activableProducts === null || activableProducts === void 0 ? void 0 : activableProducts.find(function(p) {
            var ref;
            return (p === null || p === void 0 ? void 0 : (ref = p.id) === null || ref === void 0 ? void 0 : ref.productOfferingId) === offerId;
        });
        if (!isLogged) {
            if (!isEnriched) loginManager.handleLogin();
            else mfaEnrichedManager.handleMfaEnriched({
                data: {
                    isStart: true
                }
            });
        } else {
            activationManager.handleActivation({
                product: product || productNotInCartButActivable
            });
        }
    };
    var widgetsOrganized = organizeWidgets(queryClient, widgetsForLoggedAndAnonymous, isLogged, isEnriched, isLegacy, nation, consumerOffersCSV, activableProducts || [], activatedOffers || [], items === null || items === void 0 ? void 0 : items.data, onActiveProduct);
    return {
        aemElements: widgetsOrganized
    };
};
export { useConsumerAbroad };
