import { changeTextByFlag } from "./topHero.utils";
export var useWidgetTopHero = function(widget, nationName, nationPrefix) {
    var ref, ref1, ref2, ref3, ref4;
    return {
        image: widget === null || widget === void 0 ? void 0 : widget.image,
        imageMobile: (widget === null || widget === void 0 ? void 0 : widget.imageMobile) || (widget === null || widget === void 0 ? void 0 : widget.image),
        divider: widget === null || widget === void 0 ? void 0 : widget.divider,
        disclaimer: widget === null || widget === void 0 ? void 0 : widget.disclaimer,
        action: widget === null || widget === void 0 ? void 0 : widget.customAction,
        title: changeTextByFlag((widget === null || widget === void 0 ? void 0 : widget.title) || "", nationName || "", "$NAZIONE$"),
        text: changeTextByFlag((widget === null || widget === void 0 ? void 0 : widget.description) || "", nationPrefix || "", "$PREFIX$"),
        backLabel: widget === null || widget === void 0 ? void 0 : (ref = widget.button) === null || ref === void 0 ? void 0 : ref.label,
        backUrl: widget === null || widget === void 0 ? void 0 : (ref1 = widget.button) === null || ref1 === void 0 ? void 0 : ref1.url,
        descriptionBottom: (widget === null || widget === void 0 ? void 0 : (ref2 = widget.elements) === null || ref2 === void 0 ? void 0 : (ref3 = ref2[0]) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        isShort: (widget === null || widget === void 0 ? void 0 : (ref4 = widget.isShort) === null || ref4 === void 0 ? void 0 : ref4.toLowerCase()) === "true"
    };
};
