import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-39bce620-0"
})(_templateObject());
export var ModalContent = styled.div.withConfig({
    componentId: "sc-39bce620-1"
})(_templateObject1());
