import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    overflow: hidden;\n  }\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n    overflow: hidden;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  height: 40%;\n  object-fit: cover;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    height: 100%;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  left: 25px;\n  padding: 23px 0px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  padding: ",
        ";\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    width: 100%;\n    margin: ",
        ";\n  }\n  @media (min-width: ",
        ") {\n    margin: ",
        ";\n    width: 90%;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  h1 {\n    ",
        "\n    font-family: ",
        "\n    font-weight: 400;\n    text-align: left;\n  }\n\n  p {\n    width: 100%;\n    padding-bottom: 12px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  @media (min-width: ",
        ") {\n    flex-direction: row-reverse;\n    gap: 30px;\n  }\n  @media (min-width: ",
        ") {\n    margin-top: ",
        ";\n    flex-direction: column;\n    gap: 27px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
import { breakpoints, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Container = styled.div.withConfig({
    componentId: "sc-f74b5fd8-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var ImageStyle = styled.img.withConfig({
    componentId: "sc-f74b5fd8-1"
})(_templateObject1(), breakpoints.desktop);
export var PillContainer = styled.div.withConfig({
    componentId: "sc-f74b5fd8-2"
})(_templateObject2());
export var VoucherContainer = styled.div.withConfig({
    componentId: "sc-f74b5fd8-3"
})(_templateObject3(), pxToRem(24, 35, 40, 15), breakpoints.desktop, pxToRem(30), breakpoints.tablet, pxToRem(0, 35, 40));
export var FirstSection = styled.div.withConfig({
    componentId: "sc-f74b5fd8-4"
})(_templateObject4(), pxToCssFont(27, 45), fonts.exbold);
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-f74b5fd8-5"
})(_templateObject5(), breakpoints.tablet, breakpoints.desktop, pxToRem(25));
