import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { API } from "@vfit/shared/data-access";
import { LoggerInstance, handleUseQuery } from "@vfit/shared/providers";
import { dxlConsumerCloudClient } from "libs/shared/providers/src/lib/fetcher";
import { errorMock } from "../../utils/utils";
/**
 * This method is used to call DXL Operation Retrieve User service
 * Method: GET
 * @returns
 */ var getUserService = function(userName, customOptions) {
    return dxlConsumerCloudClient.get("".concat(API.DXL_CREATE_FACTOR, "/").concat(userName), _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }));
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the identifyService
 * @param customOptions
 * @returns
 */ export var getUser = function(userName, customOptions) {
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "dxl-user";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var result = getUserService(userName, customOptions);
            return result;
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions.enabled : false,
            onSuccess: function(data) {
                var ref;
                localStorage.setItem("dxl-user", JSON.stringify(data));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
            },
            onError: function(error) {
                var ref;
                LoggerInstance.error("ERROR - dxl-user", error);
                localStorage.setItem("dxl-user", JSON.stringify(errorMock("dxl-user")));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
