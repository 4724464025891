import { useState } from "react";
var useCardFunctionality = function() {
    var ref = useState(false), isShowCoverageTool = ref[0], setIsShowCoverageTool = ref[1];
    var ref1 = useState(false), isShowWhatsapp = ref1[0], setIsShowWhatsapp = ref1[1];
    var ref2 = useState({
        show: false
    }), modalDetails = ref2[0], setModalDetails = ref2[1];
    return {
        isShowCoverageTool: isShowCoverageTool,
        setIsShowCoverageTool: setIsShowCoverageTool,
        isShowWhatsapp: isShowWhatsapp,
        setIsShowWhatsapp: setIsShowWhatsapp,
        modalDetails: modalDetails,
        setModalDetails: setModalDetails
    };
};
export { useCardFunctionality };
