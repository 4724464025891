import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useState } from "react";
import { changeTextByFlag, getItemsString } from "./searchingOffersEu.utils";
export var useSearchingOffersEu = function(widget, nationName, offerName) {
    var ref, ref1, ref2, ref3;
    var title = widget.title, fieldTitle = widget.fieldTitle, disclaimer = widget.disclaimer, column2 = widget.column2, column3 = widget.column3;
    var items = getItemsString(widget.offerList);
    var ref4 = useState(offerName ? (ref = widget.offerList) === null || ref === void 0 ? void 0 : ref.filter(function(o) {
        var ref;
        return ((ref = o.offerName) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === (offerName === null || offerName === void 0 ? void 0 : offerName.toLowerCase());
    }) : []), selectedOffer = ref4[0], setSelectedOffer = ref4[1];
    var onSelectOffer = function(e) {
        setSelectedOffer(widget.offerList.filter(function(o) {
            return o.offerName === e.target.value;
        }));
    };
    // eslint-disable-next-line no-prototype-builtins
    if (widget.hasOwnProperty("column3")) {
        var ref5, ref6, ref7, ref8, ref9;
        return _object_spread_props(_object_spread({}, widget), {
            title: changeTextByFlag(title, nationName || "", "$NAZIONE$"),
            placeholder: fieldTitle,
            items: items,
            elements: [],
            selectedOffer: selectedOffer,
            onSelectOffer: onSelectOffer,
            column2: _object_spread_props(_object_spread({}, column2), {
                subColumn1: _object_spread_props(_object_spread({}, column2 === null || column2 === void 0 ? void 0 : column2.subColumn1), {
                    description: changeTextByFlag(column2 === null || column2 === void 0 ? void 0 : (ref5 = column2.subColumn1) === null || ref5 === void 0 ? void 0 : ref5.description, nationName || "", "$NAZIONE$")
                }),
                subColumn2: _object_spread_props(_object_spread({}, column2 === null || column2 === void 0 ? void 0 : column2.subColumn2), {
                    description: changeTextByFlag(column2 === null || column2 === void 0 ? void 0 : (ref6 = column2.subColumn2) === null || ref6 === void 0 ? void 0 : ref6.description, nationName || "", "$NAZIONE$")
                }),
                subColumn3: _object_spread_props(_object_spread({}, column2 === null || column2 === void 0 ? void 0 : column2.subColumn3), {
                    description: changeTextByFlag(column2 === null || column2 === void 0 ? void 0 : (ref7 = column2.subColumn3) === null || ref7 === void 0 ? void 0 : ref7.description, nationName || "", "$NAZIONE$")
                })
            }),
            column3: _object_spread_props(_object_spread({}, column3), {
                subColumn1: _object_spread_props(_object_spread({}, column3 === null || column3 === void 0 ? void 0 : column3.subColumn1), {
                    description: changeTextByFlag(column3 === null || column3 === void 0 ? void 0 : (ref8 = column3.subColumn1) === null || ref8 === void 0 ? void 0 : ref8.description, nationName || "", "$NAZIONE$")
                }),
                subColumn2: _object_spread_props(_object_spread({}, column3 === null || column3 === void 0 ? void 0 : column3.subColumn2), {
                    description: changeTextByFlag(column3 === null || column3 === void 0 ? void 0 : (ref9 = column3.subColumn2) === null || ref9 === void 0 ? void 0 : ref9.description, nationName || "", "$NAZIONE$")
                })
            }),
            disclaimer: changeTextByFlag(disclaimer, nationName || "", "$NAZIONE$")
        });
    }
    return _object_spread_props(_object_spread({}, widget), {
        title: changeTextByFlag(title, nationName || "", "$NAZIONE$"),
        placeholder: fieldTitle,
        items: items,
        elements: [],
        selectedOffer: selectedOffer,
        onSelectOffer: onSelectOffer,
        column2: _object_spread_props(_object_spread({}, column2), {
            subColumn1: _object_spread_props(_object_spread({}, column2 === null || column2 === void 0 ? void 0 : column2.subColumn1), {
                description: changeTextByFlag(column2 === null || column2 === void 0 ? void 0 : (ref1 = column2.subColumn1) === null || ref1 === void 0 ? void 0 : ref1.description, nationName || "", "$NAZIONE$")
            }),
            subColumn2: _object_spread_props(_object_spread({}, column2 === null || column2 === void 0 ? void 0 : column2.subColumn2), {
                description: changeTextByFlag(column2 === null || column2 === void 0 ? void 0 : (ref2 = column2.subColumn2) === null || ref2 === void 0 ? void 0 : ref2.description, nationName || "", "$NAZIONE$")
            }),
            subColumn3: _object_spread_props(_object_spread({}, column2 === null || column2 === void 0 ? void 0 : column2.subColumn3), {
                description: changeTextByFlag(column2 === null || column2 === void 0 ? void 0 : (ref3 = column2.subColumn3) === null || ref3 === void 0 ? void 0 : ref3.description, nationName || "", "$NAZIONE$")
            })
        }),
        disclaimer: changeTextByFlag(disclaimer, nationName || "", "$NAZIONE$")
    });
};
