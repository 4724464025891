import { useEffect } from "react";
import { trackLink } from "@vfit/shared/data-access";
export var useTagging = function() {
    // region Tagging Simple buttons
    var trackSimpleButtonInfo = function(event) {
        var ref;
        trackLink((ref = event["target"]) === null || ref === void 0 ? void 0 : ref["textContent"]);
    };
    var simpleButtonTagging = function(widgetClass, removeEvent) {
        var simpleButtonsInfo = document.querySelectorAll(widgetClass);
        simpleButtonsInfo.forEach(function(sB) {
            var buttons = sB.querySelectorAll("button");
            buttons.forEach(function(button) {
                if (removeEvent) button.removeEventListener("click", trackSimpleButtonInfo);
                else button.addEventListener("click", trackSimpleButtonInfo);
            });
        });
    };
    // endregion
    // region Tagging search button
    var trackSearchButton = function(event) {
        var ref;
        trackLink((ref = event["target"]) === null || ref === void 0 ? void 0 : ref["textContent"], "users", "click", [
            "ui-interaction",
            "search_apply", 
        ]);
    };
    var searchButtonTagging = function(removeEvent) {
        var ref, ref1;
        var searchButton = document.getElementById("multi-selection-filter-search-button");
        if (removeEvent) searchButton === null || searchButton === void 0 ? void 0 : (ref = searchButton.removeEventListener) === null || ref === void 0 ? void 0 : ref.call(searchButton, "click", trackSearchButton);
        else searchButton === null || searchButton === void 0 ? void 0 : (ref1 = searchButton.addEventListener) === null || ref1 === void 0 ? void 0 : ref1.call(searchButton, "click", trackSearchButton);
    };
    // endregion
    useEffect(function() {
        searchButtonTagging();
        simpleButtonTagging(".card-info-widget");
        simpleButtonTagging(".faq-widget");
        simpleButtonTagging(".text-info-widget");
        simpleButtonTagging(".app-qr-widget");
        return function() {
            searchButtonTagging(true);
            simpleButtonTagging(".card-info-widget", true);
            simpleButtonTagging(".faq-widget", true);
            simpleButtonTagging(".text-info-widget", true);
            simpleButtonTagging(".app-qr-widget", true);
        };
    }, []);
    return null;
};
