import { errorManager, ErrorService } from "@vfit/shared/providers";
var showError = function(handleCloseModal) {
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: "Ops!",
        message: "Si \xe8 verificato un errore, riprovare in seguito",
        onClose: handleCloseModal,
        actionEvent: handleCloseModal,
        actionText: "Chiudi"
    });
};
export { showError };
