import { LoginErrorType, usePostEnrichmentV2, useRegisterDevice } from "@vfit/consumer/data-access";
import { useState, useEffect } from "react";
import { useUserContext } from "../context/user/user.context";
export var useMfaEnriched = function() {
    var setDeviceId = useUserContext().setDeviceId;
    var ref = useState(false), isSuccess = ref[0], setIsSuccess = ref[1];
    var ref1 = useState(false), isError = ref1[0], setIsError = ref1[1];
    var MFA = LoginErrorType.MFA, Enrollment = LoginErrorType.Enrollment, ErrorGeneric = LoginErrorType.ErrorGeneric, Default = LoginErrorType.Default;
    var ref2 = useState(Default), errorType = ref2[0], setErrorType = ref2[1];
    var ref3 = useRegisterDevice(), deviceData = ref3.data, deviceMutation = ref3.mutate, isSuccessDevice = ref3.isSuccess, isErrorDevice = ref3.isError, resetDevice = ref3.reset;
    var ref4 = usePostEnrichmentV2(), loginData = ref4.data, mutateLogin = ref4.mutate, isSuccessLogin = ref4.isSuccess, isErrorLogin = ref4.isError, errorLogin = ref4.error, resetLogin = ref4.reset;
    var registerDeviceAndLoginEnriched = function() {
        deviceMutation();
    };
    var resetFlow = function() {
        setErrorType(Default);
        setIsError(false);
        resetDevice();
        resetLogin();
    };
    useEffect(function() {
        if (isSuccessDevice && deviceData.installationId) {
            var deviceId = deviceData.installationId;
            setDeviceId(deviceId);
            mutateLogin(deviceId);
        }
    }, [
        deviceData,
        isSuccessDevice
    ]);
    useEffect(function() {
        setIsSuccess(isSuccessLogin && isSuccessDevice);
    }, [
        isSuccessLogin,
        isSuccessDevice
    ]);
    useEffect(function() {
        setIsError(isErrorLogin || isErrorDevice);
    }, [
        isErrorLogin,
        isErrorDevice
    ]);
    useEffect(function() {
        if (isErrorLogin && errorLogin) {
            if (errorLogin.status == "401") {
                var ref;
                setErrorType(((ref = errorLogin.body) === null || ref === void 0 ? void 0 : ref.code.includes("network-enrichment-challenge-required")) ? MFA : Enrollment);
            } else {
                setErrorType(ErrorGeneric);
            }
        }
    }, [
        isErrorLogin
    ]);
    return {
        deviceId: deviceData === null || deviceData === void 0 ? void 0 : deviceData.installationId,
        registerDeviceAndLoginEnriched: registerDeviceAndLoginEnriched,
        resetFlow: resetFlow,
        loginData: loginData,
        isLoading: !loginData && !errorLogin,
        isSuccess: isSuccess,
        isError: isError,
        errorLogin: errorLogin,
        errorType: errorType
    };
};
