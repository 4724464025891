import { purify } from "@vfit/shared/themes";
var getCmsLabel = function(queryClient, widgetKey, valueKey) {
    var ref, ref1, ref2, ref3, ref4;
    var cmsEditorialConfig = queryClient.getQueryData("getTariffeEsteroEditorialConfigs");
    if (!(cmsEditorialConfig === null || cmsEditorialConfig === void 0 ? void 0 : cmsEditorialConfig[widgetKey])) return "";
    var widgetElements = (cmsEditorialConfig === null || cmsEditorialConfig === void 0 ? void 0 : (ref = cmsEditorialConfig[widgetKey]) === null || ref === void 0 ? void 0 : (ref1 = ref["editorialconfigs"]) === null || ref1 === void 0 ? void 0 : ref1["elements"]) || [];
    if (!widgetElements || (widgetElements === null || widgetElements === void 0 ? void 0 : widgetElements.length) === 0) return "";
    var widgetLabel = (ref2 = widgetElements === null || widgetElements === void 0 ? void 0 : widgetElements.find(function(el) {
        return el.id == valueKey;
    })) === null || ref2 === void 0 ? void 0 : ref2.value;
    if (!widgetLabel) return "";
    var parser = new DOMParser();
    var doc = parser === null || parser === void 0 ? void 0 : parser.parseFromString(widgetLabel, "text/html");
    var outerP = doc.body.querySelector("p");
    if (outerP) {
        outerP.outerHTML = outerP.innerHTML;
    }
    return ((ref4 = purify([
        (doc === null || doc === void 0 ? void 0 : (ref3 = doc.body) === null || ref3 === void 0 ? void 0 : ref3.innerHTML) || ""
    ])) === null || ref4 === void 0 ? void 0 : ref4[0]) || "";
};
export { getCmsLabel };
