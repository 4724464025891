import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n\n  .container {\n    margin: 40px 10px 10px;\n    width: 310px;\n\n    @media (min-width: ",
        ") {\n      margin: 40px;\n    }\n\n    @media (min-width: ",
        ") {\n      margin: 40px 60px;\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n\n  .button-slide-container {\n    margin-bottom: 16px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n  font-family: ",
        ";\n  ",
        "\n  margin: 0;\n  font-weight: 400;\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    padding-top: 8px;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    padding-top: 8px;\n    ",
        "\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  ",
        "\n  margin: 0;\n  margin-top: 32px;\n  text-align: left;\n  font-weight: 400;\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  display: flex;\n  justify-content: center;\n\n  .button-slide-container {\n    width: 100%;\n\n    @media (min-width: ",
        ") {\n      width: 258px;\n    }\n\n    @media (min-width: ",
        ") {\n      width: 296px;\n    }\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var FormWrapper = styled.div.withConfig({
    componentId: "sc-23a6fe6b-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var ModalWrap = styled.div.withConfig({
    componentId: "sc-23a6fe6b-1"
})(_templateObject1());
export var ModalTitle = styled.p.withConfig({
    componentId: "sc-23a6fe6b-2"
})(_templateObject2(), fonts.exbold, pxToCssFont(30, 38), colors.$262626, breakpoints.tablet, pxToCssFont(30, 38), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalDescription = styled.p.withConfig({
    componentId: "sc-23a6fe6b-3"
})(_templateObject3(), fonts.regular, pxToCssFont(20, 26), colors.$262626, breakpoints.tablet, pxToCssFont(18, 24));
export var ButtonDiv = styled.div.withConfig({
    componentId: "sc-23a6fe6b-4"
})(_templateObject4(), breakpoints.tablet, breakpoints.desktop);
