import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin: 0 25px;\n  color: ",
        ";\n  font-family: ",
        ";\n  font-weight: 400;\n\n  div {\n    display: inline;\n  }\n\n  a > div {\n    font-weight: 700;\n    color: ",
        ";\n  }\n\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    margin: 0 80px 120px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var LinkWrapper = styled.div.withConfig({
    componentId: "sc-6df311c2-0"
})(_templateObject(), colors.$7e7e7e, fonts.regular, colors.$7e7e7e, pxToCssFont(16, 22), breakpoints.bigDesktop);
