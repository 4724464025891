import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { awsConsumerClient, handleEnv, LoggerInstance } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { useMutation } from "react-query";
import { MOCK_DXL_MFA_TOKEN, retrieveUserAgent } from "@vfit/consumer/data-access";
var validateEnrichmentMFAChallengeAndLogin = function(payload, deviceId) {
    var dxlJwtMfa = sessionStorage.getItem("dxlJwtMfa");
    return awsConsumerClient.post("".concat(API.DXL_VALIDATE_MFA_ENRICHMENT_CHALLENGE_AND_LOGIN_V1), _object_spread({}, payload), {
        headers: _object_spread({
            "X-Device-UserAgent": retrieveUserAgent(),
            "X-Device-Id": deviceId
        }, dxlJwtMfa && dxlJwtMfa != "undefined" && {
            "vf-a-appconsumermfa": dxlJwtMfa
        })
    });
};
export var useValidateEnrichmentMFAChallengeAndLogin = function() {
    return useMutation("useValidateEnrichmentMFAChallengeAndLogin", function(param) {
        var payload = param.payload, deviceId = param.deviceId;
        return validateEnrichmentMFAChallengeAndLogin(_object_spread({}, payload), deviceId);
    }, {
        onSuccess: function(data) {
            LoggerInstance.error("SUCCESS - useValidateEnrichmentMFAChallengeAndLogin", data);
        },
        onError: function(error) {
            var isDevLocal = handleEnv("NODE_ENV") === "LOCAL" || handleEnv("NODE_ENV") === "development";
            var jwtMFA = error.headers.get("vf-a-appconsumermfa");
            if (jwtMFA || isDevLocal) {
                sessionStorage.setItem("dxlJwtMfa", isDevLocal ? MOCK_DXL_MFA_TOKEN : jwtMFA);
            }
            LoggerInstance.error("ERROR - useValidateEnrichmentMFAChallengeAndLogin", error);
        }
    });
};
