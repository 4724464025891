import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    margin: 48px 0 8px;\n    border-bottom: solid ",
        ";\n\n    @media (min-width: ",
        ") {\n        min-width: 563px;\n    }\n\n    @media (min-width: ",
        ") {\n        width: 842px;\n    }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n    margin: 0;\n    padding: 12px 24px;\n    text-align: center;\n    ",
        "\n\n    &.login {\n        font-family: ",
        ";\n        border-bottom: solid ",
        ";\n    }\n\n    &.register {\n        font-family: ",
        ";\n        border-bottom: solid ",
        ";\n\n        &:hover {\n            cursor: pointer;\n        }\n    }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var TabsWrapper = styled.div.withConfig({
    componentId: "sc-72084850-0"
})(_templateObject(), colors.$bebebe, breakpoints.tablet, breakpoints.desktop);
export var Tab = styled.p.withConfig({
    componentId: "sc-72084850-1"
})(_templateObject1(), pxToCssFont(26, 38), fonts.exbold, colors.$e60000, fonts.light, colors.$bebebe);
