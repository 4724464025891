var formatProducts = function(elements) {
    return elements.map(function(el) {
        return {
            tagLine: el === null || el === void 0 ? void 0 : el.tagLine,
            offerName: el === null || el === void 0 ? void 0 : el.overrideOfferName,
            frontLabel: el === null || el === void 0 ? void 0 : el.frontLabel,
            bottomLabel: el === null || el === void 0 ? void 0 : el.offerLabel,
            overrideCategory: el === null || el === void 0 ? void 0 : el.overrideCategory,
            additionalOfferDetails: el === null || el === void 0 ? void 0 : el.additionalOfferDetails,
            offerSpecs: el === null || el === void 0 ? void 0 : el.shortCharacteristics,
            offerId: el === null || el === void 0 ? void 0 : el.dxlId,
            isEditorialCard: el === null || el === void 0 ? void 0 : el.isEditorialCard,
            taggingProductPrice: el === null || el === void 0 ? void 0 : el.taggingProductPrice,
            priceDescription: el === null || el === void 0 ? void 0 : el.overrideCostActivationHtml,
            unaTantumPriceDescription: el === null || el === void 0 ? void 0 : el.overrideUnaTantumCostActivationHtml,
            shortDescription: el === null || el === void 0 ? void 0 : el.shortDescription,
            image: el === null || el === void 0 ? void 0 : el.imageBackgroundUrl,
            price: el === null || el === void 0 ? void 0 : el.overrideCostRenewalHtml,
            actions: el === null || el === void 0 ? void 0 : el.actions,
            pills: el === null || el === void 0 ? void 0 : el.tags,
            retarget: el === null || el === void 0 ? void 0 : el.retarget,
            version: el === null || el === void 0 ? void 0 : el.version,
            taggingProductName: el === null || el === void 0 ? void 0 : el.taggingProductName
        };
    }).filter(Boolean);
};
export { formatProducts };
