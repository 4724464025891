import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { awsConsumerClient, handleUseQuery, LoggerInstance } from "@vfit/shared/providers";
import { API, AppMobile, checkWindow, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { retrieveUserAgent } from "@vfit/consumer/data-access";
var getActivable = function(payload, headers) {
    var ref;
    return awsConsumerClient.get("".concat(API.DXL_ACTIVABLE_PRODUCTS), {
        searchParams: _object_spread({}, payload),
        headers: _object_spread({}, ((ref = Object.keys(headers)) === null || ref === void 0 ? void 0 : ref.length) > 0 && _object_spread({}, headers))
    });
};
export var useGetActivable = function(msisdn, productType, deviceId) {
    var enabled = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false;
    var keysDependency = [
        "getActivableProducts"
    ];
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var isApp = AppMobile.checkIsApp();
            var appSession = isApp && checkWindow() ? getFromLocalStorageByKey("appSession") : null;
            var headers = {
                "X-Device-UserAgent": isApp && (appSession === null || appSession === void 0 ? void 0 : appSession.customerUserAgent) ? appSession === null || appSession === void 0 ? void 0 : appSession.customerUserAgent : retrieveUserAgent(),
                "X-Device-Id": isApp && (appSession === null || appSession === void 0 ? void 0 : appSession.installationId) ? appSession === null || appSession === void 0 ? void 0 : appSession.installationId : deviceId
            };
            return getActivable({
                sims: msisdn,
                productType: productType
            }, headers);
        },
        options: {
            enabled: enabled,
            onSuccess: function(data) {
                LoggerInstance.debug("USER: catalog success", data);
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - getActivableProducts", error);
            }
        }
    };
    return handleUseQuery(options);
};
