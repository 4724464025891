import React from "react";
export var DEFAULT_ACTIVABLE = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: undefined
};
export var DEFAULT_IDENTIFY = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: undefined
};
export var DEFAULT_ITEMS = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: undefined
};
