import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useMutation, useQueryClient } from "react-query";
import { errorMock } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
/**
 * Silent login api service.
 * API	POST authentication/external-user/v1/silentLogin
 * In the first time -  This request return to us the main information  about the user
 * (The next times it's just checks if the user is login or not)
 * Method:	POST
 * QueryString Params: salesChannel=selfService
 * @param options
 * @returns
 */ export var silentLoginService = function(customOptions) {
    return nextClient.post(API.SILENT_LOGIN, {}, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Silent Login mutation
 * @returns
 */ export var useSilentLogin = function(customOptions) {
    var queryClient = useQueryClient();
    var keyString = "silentLogin";
    return useMutation("loginCheckMutation", function(r7FlowIndicator) {
        var options;
        if (!r7FlowIndicator) {
            options = {
                headers: _object_spread_props(_object_spread({}, nextClient.baseHeaders), {
                    r7flowindicator: "false"
                })
            };
        }
        LoggerInstance.debug("TEST R2 - options", options);
        return silentLoginService(_object_spread({
            headers: _object_spread({}, options === null || options === void 0 ? void 0 : options.headers, customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers)
        }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
            silentLoginHeaders: customOptions.silentLoginHeaders
        }));
    }, {
        onSuccess: function(data) {
            var ref;
            localStorage.setItem(keyString, JSON.stringify(data));
            queryClient.setQueryData(keyString, data);
            customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
        },
        onError: function(error) {
            var ref;
            sessionStorage.removeItem("uxfauthorization");
            LoggerInstance.error("ERROR - ".concat(keyString, ": "), error);
            queryClient.setQueryData(keyString, errorMock("".concat(keyString), error));
            localStorage.setItem("".concat(keyString), JSON.stringify(errorMock("".concat(keyString), error)));
            customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
        }
    });
};
