import { SimpleTextModalService } from "./simpleTextModalManager";
import { SimpleComponentModalService } from "./simpleComponentModalManager";
import { LoginService } from "./loginManager";
import { MfaService } from "./mfaManager";
import { MfaEnrichedService } from "./mfaEnrichedManager";
import { ActivationService } from "./activationManager";
import { SimpleTextModalWrapper } from "./simpleTextModalManager/context";
import { SimpleComponentModalWrapper } from "./simpleComponentModalManager/context";
import { ModalLoginWrapper } from "./loginManager/context";
import { ModalActivationWrapper } from "./activationManager/context";
import { ModalMfaWrapper } from "./mfaManager/context";
import { ModalMfaEnrichedWrapper } from "./mfaEnrichedManager/context";
import { ProductsProvider } from "./products/context";
export var simpleTextModalManager = new SimpleTextModalService();
export var simpleComponentModalManager = new SimpleComponentModalService();
export var loginManager = new LoginService();
export var activationManager = new ActivationService();
export var mfaManager = new MfaService();
export var mfaEnrichedManager = new MfaEnrichedService();
export var productsProvider = new ProductsProvider();
export { SimpleTextModalWrapper, SimpleComponentModalWrapper, ModalLoginWrapper, ModalActivationWrapper, ModalMfaWrapper, ModalMfaEnrichedWrapper };
