import { useDeviceType } from "@vfit/shared/hooks";
import { formatFaqProps } from "./faq.utils";
export var useWidgetFaq = function(widget) {
    var ref = formatFaqProps(widget), faq = ref.faq, topText = ref.topText;
    var isMobile = useDeviceType().isMobile;
    var containerStyle = {
        paddingTop: isMobile ? 32 : 48
    };
    var handleButtonClick = function() {
        var ref;
        window.open(widget === null || widget === void 0 ? void 0 : (ref = widget.customAction) === null || ref === void 0 ? void 0 : ref.url, "_blank");
    };
    return {
        faq: faq,
        topText: topText,
        containerStyle: containerStyle,
        divider: widget === null || widget === void 0 ? void 0 : widget.divider,
        disclaimer: (widget === null || widget === void 0 ? void 0 : widget.disclaimer) || "",
        handleButtonClick: handleButtonClick
    };
};
