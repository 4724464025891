import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  height: fit-content;\n  padding: 48px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  justify-items: center;\n  align-content: center;\n  align-items: center;\n  height: fit-content;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 800;\n  ",
        "\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 300;\n  ",
        "\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  justify-content: center;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { fonts, pxToCssFont } from "@vfit/shared/themes";
export var Main = styled.main.withConfig({
    componentId: "sc-f068464a-0"
})(_templateObject());
export var TopSection = styled.section.withConfig({
    componentId: "sc-f068464a-1"
})(_templateObject1());
export var Title = styled.h1.withConfig({
    componentId: "sc-f068464a-2"
})(_templateObject2(), fonts.regular, pxToCssFont(28, 36));
export var Description = styled.p.withConfig({
    componentId: "sc-f068464a-3"
})(_templateObject3(), fonts.light, pxToCssFont(20, 28));
export var BottomSection = styled.div.withConfig({
    componentId: "sc-f068464a-4"
})(_templateObject4());
