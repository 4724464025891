import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { changeTextByFlag } from "./worldOffers.utils";
export var useWidgetWorldOffers = function(widget, nationName, nation) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21;
    var row1 = widget.row1, row2 = widget.row2;
    var ref22 = useState([]), offers = ref22[0], setOffers = ref22[1];
    var queryClient = useQueryClient();
    var worldOffers = queryClient.getQueryData("getConsumerWorldOffers");
    useEffect(function() {
        var ref;
        var filterWorldOffers = worldOffers === null || worldOffers === void 0 ? void 0 : (ref = worldOffers.filter) === null || ref === void 0 ? void 0 : ref.call(worldOffers, function(o) {
            return (o === null || o === void 0 ? void 0 : o.slugCountry) === (nation === null || nation === void 0 ? void 0 : nation.slug);
        });
        setOffers(filterWorldOffers);
    }, [
        nation
    ]);
    return _object_spread_props(_object_spread({}, widget), {
        row1: _object_spread_props(_object_spread({}, row1), {
            column1: _object_spread_props(_object_spread({}, row1 === null || row1 === void 0 ? void 0 : row1.column1), {
                content: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row1 === null || row1 === void 0 ? void 0 : (ref = row1.column1) === null || ref === void 0 ? void 0 : ref.content, (ref1 = offers[0]) === null || ref1 === void 0 ? void 0 : ref1.fromCountrytoItaly, row1.column1.content),
                description: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row1 === null || row1 === void 0 ? void 0 : (ref2 = row1.column1) === null || ref2 === void 0 ? void 0 : ref2.description, nationName, "$NAZIONE$")
            }),
            column2: _object_spread_props(_object_spread({}, row1 === null || row1 === void 0 ? void 0 : row1.column2), {
                content: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row1 === null || row1 === void 0 ? void 0 : (ref3 = row1.column2) === null || ref3 === void 0 ? void 0 : ref3.content, (ref4 = offers[0]) === null || ref4 === void 0 ? void 0 : ref4.fromCountrytoCountry, row1 === null || row1 === void 0 ? void 0 : (ref5 = row1.column2) === null || ref5 === void 0 ? void 0 : ref5.content),
                description: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row1 === null || row1 === void 0 ? void 0 : (ref6 = row1.column2) === null || ref6 === void 0 ? void 0 : ref6.description, nationName, "$NAZIONE$")
            }),
            column3: _object_spread_props(_object_spread({}, row1 === null || row1 === void 0 ? void 0 : row1.column3), {
                content: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row1 === null || row1 === void 0 ? void 0 : (ref7 = row1.column3) === null || ref7 === void 0 ? void 0 : ref7.content, (ref8 = offers[0]) === null || ref8 === void 0 ? void 0 : ref8.fromCountrytoWorld, row1 === null || row1 === void 0 ? void 0 : (ref9 = row1.column3) === null || ref9 === void 0 ? void 0 : ref9.content),
                description: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row1 === null || row1 === void 0 ? void 0 : (ref10 = row1.column3) === null || ref10 === void 0 ? void 0 : ref10.description, nationName, "$NAZIONE$")
            })
        }),
        row2: _object_spread_props(_object_spread({}, row2), {
            column1: _object_spread_props(_object_spread({}, row2 === null || row2 === void 0 ? void 0 : row2.column1), {
                content: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row2 === null || row2 === void 0 ? void 0 : (ref11 = row2.column1) === null || ref11 === void 0 ? void 0 : ref11.content, (ref12 = offers[0]) === null || ref12 === void 0 ? void 0 : ref12.receivedCalls, row2 === null || row2 === void 0 ? void 0 : (ref13 = row2.column1) === null || ref13 === void 0 ? void 0 : ref13.content),
                description: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row2 === null || row2 === void 0 ? void 0 : (ref14 = row2.column1) === null || ref14 === void 0 ? void 0 : ref14.description, nationName, "$NAZIONE$")
            }),
            column2: _object_spread_props(_object_spread({}, row2 === null || row2 === void 0 ? void 0 : row2.column2), {
                content: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row2 === null || row2 === void 0 ? void 0 : (ref15 = row2.column2) === null || ref15 === void 0 ? void 0 : ref15.content, offers === null || offers === void 0 ? void 0 : (ref16 = offers[0]) === null || ref16 === void 0 ? void 0 : ref16.sentSms, row2.column2.content),
                description: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row2 === null || row2 === void 0 ? void 0 : (ref17 = row2.column2) === null || ref17 === void 0 ? void 0 : ref17.description, nationName, "$NAZIONE$")
            }),
            column3: _object_spread_props(_object_spread({}, row2 === null || row2 === void 0 ? void 0 : row2.column3), {
                content: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row2 === null || row2 === void 0 ? void 0 : (ref18 = row2.column3) === null || ref18 === void 0 ? void 0 : ref18.content, offers === null || offers === void 0 ? void 0 : (ref19 = offers[0]) === null || ref19 === void 0 ? void 0 : ref19.internetFee, row2 === null || row2 === void 0 ? void 0 : (ref20 = row2.column3) === null || ref20 === void 0 ? void 0 : ref20.content),
                description: changeTextByFlag === null || changeTextByFlag === void 0 ? void 0 : changeTextByFlag(row2 === null || row2 === void 0 ? void 0 : (ref21 = row2.column3) === null || ref21 === void 0 ? void 0 : ref21.description, nationName, "$NAZIONE$")
            })
        }),
        isOfferFound: offers === null || offers === void 0 ? void 0 : offers.length,
        nation: nation
    });
};
