import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useQueryClient } from "react-query";
import { useCallback, useState } from "react";
import { AppMobile } from "@vfit/shared/data-access";
export var useSearchNation = function(widget) {
    var title = widget.title, customAction = widget.customAction, hiddenContinent = widget.hiddenContinent;
    var queryClient = useQueryClient();
    var nationsList = queryClient.getQueryData("getNations") || [];
    var ref = useState(""), selectedPrimaryItem = ref[0], setSelectedPrimaryItem = ref[1];
    var ref1 = useState(""), selectedSecondaryItem = ref1[0], setSelectedSecondaryItem = ref1[1];
    var ref2 = useState(""), selectedId = ref2[0], setSelectedId = ref2[1];
    var ref3 = useState(customAction), customUrl = ref3[0], setCustomUrl = ref3[1];
    var appTrue = AppMobile.checkIsApp() ? "&app=true" : "";
    var reformatObj = function(list, key) {
        var ref;
        var pArr = [];
        list === null || list === void 0 ? void 0 : (ref = list.map) === null || ref === void 0 ? void 0 : ref.call(list, function(obj) {
            if ((pArr === null || pArr === void 0 ? void 0 : pArr.findIndex(function(x) {
                return x == (obj === null || obj === void 0 ? void 0 : obj[key]);
            })) === -1) {
                pArr.push(obj[key]);
            }
        });
        return pArr;
    };
    var ref4 = useState(reformatObj(nationsList, "continente")), primaryListItems = ref4[0];
    var ref5 = useState(reformatObj(nationsList, "nome")), secondaryListItems = ref5[0], setSecondaryListItems = ref5[1];
    var handleFilter = useCallback(function(e) {
        var ref;
        setSelectedSecondaryItem("");
        var filteredList = nationsList === null || nationsList === void 0 ? void 0 : (ref = nationsList.filter) === null || ref === void 0 ? void 0 : ref.call(nationsList, function(c) {
            var ref;
            return (c === null || c === void 0 ? void 0 : c["continente"]) === (e === null || e === void 0 ? void 0 : (ref = e.target) === null || ref === void 0 ? void 0 : ref.value);
        });
        var transFilteredList = reformatObj(filteredList, "nome");
        setSelectedPrimaryItem(e.target.value);
        setSecondaryListItems(transFilteredList);
    }, []);
    var handleSelectedId = useCallback(function(e) {
        var ref;
        var selectedNation = (nationsList === null || nationsList === void 0 ? void 0 : (ref = nationsList.find) === null || ref === void 0 ? void 0 : ref.call(nationsList, function(n) {
            return (n === null || n === void 0 ? void 0 : n.nome) === e.target.value;
        })) || {};
        setSelectedId(selectedNation === null || selectedNation === void 0 ? void 0 : selectedNation.slug);
        setSelectedSecondaryItem(e.target.value);
        if (!(customUrl === null || customUrl === void 0 ? void 0 : customUrl.url)) {
            var ref1;
            var getCountry = nationsList === null || nationsList === void 0 ? void 0 : nationsList.filter(function(c) {
                return c.nome === e.target.value;
            });
            if ((ref1 = getCountry[0]) === null || ref1 === void 0 ? void 0 : ref1.isEU) {
                setCustomUrl(_object_spread_props(_object_spread({}, customUrl), {
                    url: "/tariffe-estero/dettaglio-paese-ue?country="
                }));
            } else {
                setCustomUrl(_object_spread_props(_object_spread({}, customUrl), {
                    url: "/tariffe-estero/dettaglio-paese-mondo?country="
                }));
            }
        }
    }, []);
    return {
        title: title,
        hiddenContinent: hiddenContinent,
        primaryListItems: primaryListItems,
        secondaryListItems: secondaryListItems,
        handleFilter: handleFilter,
        selectedPrimaryItem: selectedPrimaryItem,
        selectedSecondaryItem: selectedSecondaryItem,
        handleSelectedId: handleSelectedId,
        selectedId: selectedId,
        customUrl: customUrl,
        customAction: customAction,
        appTrue: appTrue,
        hideFilteredItems: true
    };
};
