import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
var ref;
import { getTechnology } from "@vfit/consumer/ibuyfixed";
import { productsProvider } from "../../providers";
export var OVERRIDE_DEBUG_MODE = ((ref = process.env) === null || ref === void 0 ? void 0 : ref["NX_ENABLE_ROAMING_DEBUG"]) === "TRUE" || false;
export var ENABLE_DEBUG = OVERRIDE_DEBUG_MODE || "production" === "development";
var getTagging = function(eventLabel, journey_name, pageType, productId) {
    var ref, ref1, ref2, ref3;
    var priceRegex = /(\d+,\d{2}€)/;
    var products = ((ref = productsProvider.getProducts()) === null || ref === void 0 ? void 0 : ref.filter(function(el) {
        var ref;
        if (!productId) return true;
        return ((ref = el.dxlId) === null || ref === void 0 ? void 0 : ref.toString()) === (productId === null || productId === void 0 ? void 0 : productId.toString());
    })) || [];
    var opts = _object_spread(_object_spread_props(_object_spread({}, eventLabel && {
        event_label: eventLabel
    }), {
        event_category: "sales",
        page_subsection: "estero",
        page_section: "eshop",
        page_type: pageType,
        journey_type: "journey",
        journey_name: journey_name
    }), (products === null || products === void 0 ? void 0 : products.length) > 0 && {
        product_technology: (ref1 = getTechnology()) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.originalTechType) === null || ref2 === void 0 ? void 0 : ref2.replace(/["']/g, ""),
        product_category: products === null || products === void 0 ? void 0 : products.map(function(p) {
            return "addon";
        }),
        product_id: products === null || products === void 0 ? void 0 : products.map(function(p) {
            return p.dxlId || "";
        }),
        product_name: products === null || products === void 0 ? void 0 : products.map(function(p) {
            return p.overrideOfferName || "";
        }),
        product_price: products === null || products === void 0 ? void 0 : products.map(function(p) {
            var ref, ref1;
            return ((ref1 = p === null || p === void 0 ? void 0 : (ref = p.overrideCostRenewalHtml) === null || ref === void 0 ? void 0 : ref.match(priceRegex)) === null || ref1 === void 0 ? void 0 : ref1[1]) || "";
        }),
        product_target_segment: products === null || products === void 0 ? void 0 : products.map(function(p) {
            return "consumer";
        }),
        product_quantity: (products === null || products === void 0 ? void 0 : (ref3 = products.length) === null || ref3 === void 0 ? void 0 : ref3.toString()) || "0"
    });
    return {
        opts: opts
    };
};
export { getTagging };
