import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    div {\n      ",
        "\n    }\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import { breakpoints, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-aa132b2e-0"
})(_templateObject(), pxToCssFont(18, 16), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalContent = styled.div.withConfig({
    componentId: "sc-aa132b2e-1"
})(_templateObject1());
