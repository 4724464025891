import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n\n  p {\n    font-size: ",
        ";\n    margin-top: 0;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 32px;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  font-size: ",
        ";\n  line-height: 26px;\n  margin-bottom: 8px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-weight: 700;\n  ",
        "\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  font-weight: 400;\n  margin-bottom: 16px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { breakpoints, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Wraper = styled.div.withConfig({
    componentId: "sc-23713e7d-0"
})(_templateObject(), pxToRem(20));
export var Title = styled.div.withConfig({
    componentId: "sc-23713e7d-1"
})(_templateObject1(), fonts.exbold, pxToCssFont(36, 45), breakpoints.tablet, pxToCssFont(30, 36), breakpoints.desktop, pxToCssFont(36, 45));
export var SubTitle = styled.div.withConfig({
    componentId: "sc-23713e7d-2"
})(_templateObject2(), pxToRem(20));
export var SectionTitle = styled.div.withConfig({
    componentId: "sc-23713e7d-3"
})(_templateObject3(), pxToCssFont(18, 24));
export var Section = styled(SectionTitle).withConfig({
    componentId: "sc-23713e7d-4"
})(_templateObject4());
