import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-wrap: wrap;\n  gap: 32px;\n  justify-content: center;\n  padding-bottom: 40px;\n\n  button,\n  a {\n    min-width: 320px;\n    width: fit-content;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 36px;\n  p {\n    margin: 0;\n  }\n  text-align: center;\n  ",
        "\n\n  @media(min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-left: 24px;\n  margin-right: 24px;\n  div {\n    font-weight: 400;\n    ",
        "\n    font-family: ",
        ";\n    color: ",
        ";\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-fdac9d88-0"
})(_templateObject());
export var Description = styled.div.withConfig({
    componentId: "sc-fdac9d88-1"
})(_templateObject1(), pxToCssFont(32, 45), breakpoints.tablet, pxToCssFont(52, 70));
export var Disclaimer = styled.div.withConfig({
    componentId: "sc-fdac9d88-2"
})(_templateObject2(), pxToCssFont(16, 22), fonts.regular, colors.$7e7e7e);
