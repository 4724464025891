import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { LoggerInstance, nextClient, handleUseQuery } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { errorMock, getCurrentUserType, IUserType } from "@vfit/consumer/data-access";
/**
 * This method allows to retrieve the customerId from the logged user cookie and authentication token
 * API  authentication/v1/user
 * Method: GET
 * QueryString Params: salesChannel=selfService
 * @returns
 */ export var userService = function(customOptions) {
    return nextClient.get(API.USER, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * This method is used to instantiate a custom useQuery hook to handle the userService
 * @param customOptions
 * @returns
 */ export var useLoggedUser = function(customOptions) {
    var queryEnabler = getCurrentUserType() === IUserType.LOGGED_USER;
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "user";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return userService(customOptions);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? queryEnabler && customOptions.enabled : queryEnabler,
            onSuccess: function(data) {
                var ref;
                localStorage.setItem("user", JSON.stringify(data));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
            },
            onError: function(error) {
                var ref;
                LoggerInstance.error("ERROR - user: ", error);
                localStorage.setItem("user", JSON.stringify(errorMock("user")));
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
