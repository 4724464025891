import { formatProducts } from "./sliderProductsHub.utils";
var useWidgetSliderProductHub = function(widget) {
    var isAlternativeOverride = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    var elements = widget.elements;
    return {
        title: isAlternativeOverride ? (widget === null || widget === void 0 ? void 0 : widget.titleAlternativeHtml) || "" : widget.titleHtml || "",
        products: formatProducts(elements)
    };
};
export { useWidgetSliderProductHub };
