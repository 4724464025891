import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: row;\n  padding: 72px 32px 32px;\n\n  @media (max-width: ",
        ") {\n    flex-direction: column-reverse;\n    padding: 72px 40px 64px;\n  }\n\n  @media (max-width: ",
        ") {\n    padding: 48px 24px 28px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0 2rem 0 0;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  border: 0.5px grey solid;\n  border-radius: 20px;\n  padding: 0 20px 20px;\n\n  h5 {\n    color: red;\n    ",
        "\n    margin: 24px 0 8px;\n\n    @media (max-width: ",
        ") {\n      ",
        ";\n    }\n  }\n\n  h3 {\n    ",
        ";\n\n    @media (max-width: ",
        ") {\n      ",
        ";\n    }\n\n    @media (max-width: ",
        ") {\n      ",
        ";\n    }\n\n    margin: 0 0 16px;\n\n    p {\n      margin: 0 0 16px;\n    }\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  font-weight: 700;\n\n  @media (max-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  margin: 0 0 8px;\n\n  @media (max-width: ",
        ") {\n    ",
        ";\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  margin: 0;\n  padding-bottom: ",
        ";\n\n  > p:first-child {\n    margin: 0;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  @media (min-width: ",
        ") and (max-width: ",
        ") {\n    display: flex;\n    flex-direction: column;\n\n    button {\n      width: 65%;\n      align-self: center;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import { breakpoints, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var VoucherInsertionManagmentCard = styled.div.withConfig({
    componentId: "sc-2abeb026-0"
})(_templateObject(), breakpoints.desktop, breakpoints.tablet);
export var StyledDivider = styled.div.withConfig({
    componentId: "sc-2abeb026-1"
})(_templateObject1());
export var VoucherLeftCard = styled.div.withConfig({
    componentId: "sc-2abeb026-2"
})(_templateObject2(), pxToCssFont(20, 30), breakpoints.desktop, pxToCssFont(20, 26), pxToCssFont(36, 45), breakpoints.desktop, pxToCssFont(24, 30), breakpoints.tablet, pxToCssFont(24, 28));
export var StyledProductName = styled.span.withConfig({
    componentId: "sc-2abeb026-3"
})(_templateObject3(), pxToCssFont(18, 24), breakpoints.desktop, pxToCssFont(14, 24));
export var VoucherRightTitle = styled.h3.withConfig({
    componentId: "sc-2abeb026-4"
})(_templateObject4(), pxToCssFont(36, 45), breakpoints.desktop, pxToCssFont(30, 38));
export var StyledVoucherApplyDescription = styled.div.withConfig({
    componentId: "sc-2abeb026-5"
})(_templateObject5(), pxToCssFont(18, 24), pxToRem(16));
export var FieldsContainer = styled.div.withConfig({
    componentId: "sc-2abeb026-6"
})(_templateObject6(), breakpoints.tablet, breakpoints.desktop);
