export var getItemsString = function(elements) {
    var itemsStr = [];
    // eslint-disable-next-line array-callback-return
    elements.map(function(el) {
        itemsStr.push(el.offerName);
    });
    return itemsStr;
};
export var changeTextByFlag = function(text, newText, flag) {
    if (flag && newText && text) {
        return text.split(flag).join(newText);
    }
    return text;
};
