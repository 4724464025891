import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  margin-top: 32px;\n  margin-bottom: 32px;\n  display: flex;\n  flex-direction: row;\n\n  @media (max-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  width: 25px;\n  margin-right: 10px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  input {\n    position: absolute;\n    margin: 0 auto;\n    opacity: 0;\n    cursor: pointer;\n    height: 20px;\n    width: 20px;\n    z-index: 3;\n  }\n\n  .checkmark::after {\n    left: 7px;\n    top: 3px;\n    width: 5px;\n    height: 10px;\n    border: solid white;\n    border-width: 0 2px 2px 0;\n    transform: rotate(45deg);\n    content: '';\n    position: absolute;\n    display: none;\n  }\n\n  .checkmark {\n    position: absolute;\n    height: ",
        ";\n    width: ",
        ";\n    border-radius: ",
        ";\n    border: ",
        " solid ",
        ";\n  }\n\n  &:hover input ~ .checkmark {\n    border: ",
        " solid ",
        ";\n  }\n\n  input:checked ~ .checkmark {\n    background-color: ",
        ";\n    border: none;\n  }\n\n  input:checked ~ .checkmark::after {\n    display: block;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: fit-content;\n  text-align: start;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  text-align: left;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var Wraper = styled.div.withConfig({
    componentId: "sc-903dd19e-0"
})(_templateObject(), fonts.regular, pxToCssFont(20, 18), breakpoints.tablet, pxToCssFont(14, 18));
export var CheckboxDiv = styled.div.withConfig({
    componentId: "sc-903dd19e-1"
})(_templateObject1());
export var Checkbox = styled.div.withConfig({
    componentId: "sc-903dd19e-2"
})(_templateObject2(), pxToRem(20), pxToRem(20), pxToRem(3), pxToRem(1), colors.$bebebe, pxToRem(1), colors.$bebebe, colors.$00697c);
export var CheckboxText = styled.div.withConfig({
    componentId: "sc-903dd19e-3"
})(_templateObject3());
export var ModalContent = styled.div.withConfig({
    componentId: "sc-903dd19e-4"
})(_templateObject4());
