var DEFAULT_SIZE = {
    default: {
        first: {
            "max-width": 364,
            height: 320
        },
        second: {
            "max-width": 724,
            height: 320
        },
        third: {
            "max-width": 724,
            height: 320
        }
    },
    mobile: {
        first: {
            "max-width": 264,
            height: 320
        },
        second: {
            "max-width": 724,
            height: 320
        },
        third: {
            "max-width": 724,
            height: 320
        }
    },
    tablet: {
        first: {
            "max-width": 264,
            height: 320
        },
        second: {
            "max-width": 724,
            height: 320
        },
        third: {
            "max-width": 724,
            height: 320
        }
    }
};
export var changeTextByFlag = function(text, newText, flag) {
    if (flag && newText && text) {
        return text.split(flag).join(newText);
    }
    return text;
};
export var getSlideSize = function(isMobile, isTablet, indexSlide) {
    var ref, ref1, ref2, ref3;
    var key = "first";
    switch(indexSlide){
        case 0:
            key = "first";
            break;
        case 1:
            key = "second";
            break;
        case 2:
            key = "third";
            break;
    }
    if (isTablet) return ((ref = DEFAULT_SIZE.tablet) === null || ref === void 0 ? void 0 : ref[key]) || ((ref1 = DEFAULT_SIZE.mobile) === null || ref1 === void 0 ? void 0 : ref1[key]);
    return isMobile ? (ref2 = DEFAULT_SIZE.mobile) === null || ref2 === void 0 ? void 0 : ref2[key] : (ref3 = DEFAULT_SIZE.default) === null || ref3 === void 0 ? void 0 : ref3[key];
};
