import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import crypto from "crypto-es";
import { useUserContext } from "../../context/user/user.context";
var useUserInfo = function() {
    var ref, ref1, ref2, ref3;
    var ref4 = useUserContext(), isLogged = ref4.isLogged, isLegacy = ref4.isLegacy, customerId = ref4.customerId, user = ref4.user, msisdn = ref4.msisdn, msisdns = ref4.msisdns;
    var SHA256 = crypto.SHA256;
    return _object_spread_props(_object_spread({
        visitor_login_status: isLogged ? "logged in" : "logged out",
        visitor_login_type: "web",
        user_recognition: "login",
        user_version: isLegacy ? "legacy" : "next",
        visitor_customer_id: (customerId === null || customerId === void 0 ? void 0 : customerId.toString()) || ""
    }, msisdn && {
        visitor_id_asset_active: SHA256(msisdn).toString()
    }, msisdns && {
        visitor_id_asset_list: msisdns === null || msisdns === void 0 ? void 0 : msisdns.map(function(el) {
            return SHA256(el).toString() || "";
        })
    }, (user === null || user === void 0 ? void 0 : (ref = user.customer) === null || ref === void 0 ? void 0 : ref.email) && {
        visitor_id_email: SHA256(user === null || user === void 0 ? void 0 : (ref1 = user.customer) === null || ref1 === void 0 ? void 0 : ref1.email).toString()
    }), {
        visitor_customer_name_first: (user === null || user === void 0 ? void 0 : (ref2 = user.customer) === null || ref2 === void 0 ? void 0 : ref2.firstname) || "",
        visitor_customer_name_last: (user === null || user === void 0 ? void 0 : (ref3 = user.customer) === null || ref3 === void 0 ? void 0 : ref3.lastname) || ""
    });
};
export { useUserInfo };
