export var changeTextByFlag = function(text, newText, flag) {
    if (flag && newText && text) {
        return text.split(flag).join(newText);
    }
    return text;
};
var MOCK_OPERATOR_RECHARGE_TABLE = {
    countryrate: {
        elements: [
            {
                title: "Da e verso Giappone",
                rates: [
                    {
                        title: "dati",
                        value: "0,3€/MB"
                    },
                    {
                        title: "chiamate in entrata",
                        value: "0,5€/MIN"
                    },
                    {
                        title: "chiamate in uscita",
                        value: "0,5€/MIN"
                    },
                    {
                        title: "sms",
                        value: "0.05€"
                    }, 
                ]
            },
            {
                title: "Da e verso l'Italia",
                rates: [
                    {
                        title: "dati",
                        value: "/"
                    },
                    {
                        title: "chiamate in entrata",
                        value: "0,2€/MIN"
                    },
                    {
                        title: "chiamate in uscita",
                        value: "0,5€/MIN"
                    },
                    {
                        title: "sms",
                        value: "0.05€"
                    }, 
                ]
            },
            {
                title: "Da e verso Mondo",
                rates: [
                    {
                        title: "dati",
                        value: "/"
                    },
                    {
                        title: "chiamate in entrata",
                        value: "0,2€/MIN"
                    },
                    {
                        title: "chiamate in uscita",
                        value: "1,50€/MIN"
                    },
                    {
                        title: "sms",
                        value: "0.05€"
                    }, 
                ]
            }, 
        ]
    }
};
