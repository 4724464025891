import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { awsConsumerClient, handleUseQuery, LoggerInstance } from "@vfit/shared/providers";
import { API } from "@vfit/shared/data-access";
import { retrieveUserAgent } from "@vfit/consumer/data-access";
var getUserAppService = function(username, deviceId) {
    return awsConsumerClient.get("".concat(API.DXL_USER_APP_V1, "/").concat(username), {
        headers: {
            "X-Device-UserAgent": retrieveUserAgent(),
            "X-Device-Id": deviceId,
            "vf-a-appconsumermfa": sessionStorage.getItem("dxlJwtMfa")
        }
    });
};
export var useGetUserApp = function(username, deviceId, customOptions) {
    var keysDependency = customOptions && customOptions.keyDependency ? customOptions.keyDependency : "dxl-user-app";
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            return getUserAppService(username, deviceId);
        },
        options: _object_spread_props(_object_spread({}, customOptions && _object_spread({}, customOptions)), {
            enabled: (customOptions === null || customOptions === void 0 ? void 0 : customOptions.enabled) !== undefined ? customOptions.enabled : false,
            onSuccess: function(data) {
                var ref;
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onSuccess) === null || ref === void 0 ? void 0 : ref.call(customOptions, data);
            },
            onError: function(error) {
                var ref;
                LoggerInstance.error("ERROR - dxl-user-app", error);
                customOptions === null || customOptions === void 0 ? void 0 : (ref = customOptions.onError) === null || ref === void 0 ? void 0 : ref.call(customOptions, error);
            }
        })
    };
    return handleUseQuery(options);
};
