import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { awsConsumerClient, handleUseQuery, LoggerInstance } from "@vfit/shared/providers";
import { API, AppMobile, checkWindow, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { retrieveUserAgent } from "@vfit/consumer/data-access";
var getItems = function(payload, headers) {
    var ref;
    return awsConsumerClient.get("".concat(API.DXL_GET_ITEMS), {
        searchParams: _object_spread({}, payload),
        headers: _object_spread({}, ((ref = Object.keys(headers)) === null || ref === void 0 ? void 0 : ref.length) > 0 && _object_spread({}, headers))
    });
};
export var useGetItems = function(contactId, msisdn, deviceId) {
    var retrieveProductCounters = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false, retrievePrice = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : true, enabled = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : false;
    var keysDependency = [
        "getItems"
    ];
    var options = {
        queryKey: keysDependency,
        queryFn: function() {
            var isApp = AppMobile.checkIsApp();
            var appSession = isApp && checkWindow() ? getFromLocalStorageByKey("appSession") : null;
            var headers = {
                "X-Device-UserAgent": isApp && (appSession === null || appSession === void 0 ? void 0 : appSession.customerUserAgent) ? appSession === null || appSession === void 0 ? void 0 : appSession.customerUserAgent : retrieveUserAgent(),
                "X-Device-Id": isApp && (appSession === null || appSession === void 0 ? void 0 : appSession.installationId) ? appSession === null || appSession === void 0 ? void 0 : appSession.installationId : deviceId
            };
            return getItems({
                contactId: contactId,
                items: msisdn,
                retrieveProductCounters: retrieveProductCounters,
                retrievePrice: retrievePrice
            }, headers);
        },
        options: {
            enabled: enabled,
            onSuccess: function(data) {
                LoggerInstance.debug("USER: items success", data);
            },
            onError: function(error) {
                LoggerInstance.error("ERROR - items error", error);
            }
        }
    };
    return handleUseQuery(options);
};
