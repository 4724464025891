import { LoginErrorType, usePostLoginV2, useRegisterDevice } from "@vfit/consumer/data-access";
import { useState, useEffect } from "react";
import { useUserContext } from "../context/user/user.context";
export var useMfaLogin = function() {
    var setDeviceId = useUserContext().setDeviceId;
    var ref = useState(""), username = ref[0], setUsername = ref[1];
    var ref1 = useState(""), password = ref1[0], setPassword = ref1[1];
    var ref2 = useState(false), isSuccess = ref2[0], setIsSuccess = ref2[1];
    var ref3 = useState(false), isError = ref3[0], setIsError = ref3[1];
    var MFA = LoginErrorType.MFA, Enrollment = LoginErrorType.Enrollment, ErrorGeneric = LoginErrorType.ErrorGeneric, Default = LoginErrorType.Default, ErrorDevice = LoginErrorType.ErrorDevice;
    var ref4 = useState(Default), errorType = ref4[0], setErrorType = ref4[1];
    var ref5 = useRegisterDevice(), deviceData = ref5.data, deviceMutation = ref5.mutate, isSuccessDevice = ref5.isSuccess, isErrorDevice = ref5.isError, isLoadingDevice = ref5.isLoading, resetDevice = ref5.reset;
    var ref6 = usePostLoginV2(), loginData = ref6.data, mutateLogin = ref6.mutate, isSuccessLogin = ref6.isSuccess, isErrorLogin = ref6.isError, isLoadingLogin = ref6.isLoading, errorLogin = ref6.error, resetLogin = ref6.reset;
    var registerDeviceAndLogin = function(user, pwd) {
        deviceMutation();
        setUsername(user);
        setPassword(pwd);
    };
    var resetFlow = function() {
        setUsername("");
        setPassword("");
        setErrorType(Default);
        setIsError(false);
        resetDevice();
        resetLogin();
    };
    useEffect(function() {
        if (isSuccessDevice && deviceData.installationId && username && password) {
            var deviceId = deviceData.installationId;
            setDeviceId(deviceId);
            mutateLogin({
                username: username,
                password: password,
                deviceId: deviceId
            });
        }
    }, [
        deviceData,
        isSuccessDevice,
        username,
        password
    ]);
    useEffect(function() {
        setIsSuccess(isSuccessLogin && isSuccessDevice);
    }, [
        isSuccessLogin,
        isSuccessDevice
    ]);
    useEffect(function() {
        setErrorType(isErrorLogin ? ErrorGeneric : ErrorDevice);
        setIsError(isErrorLogin || isErrorDevice);
    }, [
        isErrorLogin,
        isErrorDevice
    ]);
    useEffect(function() {
        if (isErrorLogin && errorLogin) {
            if (errorLogin.status == "401") {
                var ref;
                setErrorType((errorLogin === null || errorLogin === void 0 ? void 0 : (ref = errorLogin.body) === null || ref === void 0 ? void 0 : ref.code.includes("login-mfa-required")) ? MFA : Enrollment);
            } else {
                setErrorType(ErrorGeneric);
            }
        }
    }, [
        isErrorLogin
    ]);
    return {
        deviceId: deviceData === null || deviceData === void 0 ? void 0 : deviceData.installationId,
        isLoading: !loginData && !errorLogin && (isLoadingDevice || isLoadingLogin),
        registerDeviceAndLogin: registerDeviceAndLogin,
        resetFlow: resetFlow,
        loginData: loginData,
        isSuccess: isSuccess,
        isError: isError,
        errorLogin: errorLogin,
        errorType: errorType
    };
};
