import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  padding: ",
        ";\n  justify-content: center;\n  align-content: center;\n  text-align: center;\n  .capitalize-first {\n    text-transform: lowercase;\n  }\n  .capitalize-first::first-letter {\n    text-transform: uppercase;\n  }\n  @media (min-width: ",
        ") {\n    gap: 24px;\n    padding: 64px 40px;\n  }\n  @media (min-width: ",
        ") {\n    gap: 24px;\n    padding: 64px 109px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 800;\n  ",
        "\n  margin-bottom: 8px;\n  @media (min-width: ",
        ") {\n    margin-bottom: 0;\n  }\n  @media (min-width: ",
        ") {\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-weight: 300;\n  margin-bottom: 32px;\n  ",
        "\n  @media (min-width: ",
        ") {\n    margin-bottom: 0;\n  }\n  @media (min-width: ",
        ") {\n    margin-bottom: 0;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  width: fit-content;\n  margin: 0 auto;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  left: 20px;\n  top: 13px;\n  svg {\n    width: 18px;\n    height: 15px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, fonts, getTypography } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-3ea348f9-0"
})(_templateObject(), function(param) {
    var isApp = param.isApp;
    return isApp ? "inherit" : "40px 44px";
}, breakpoints.tablet, breakpoints.desktop);
export var Title = styled.div.withConfig({
    componentId: "sc-3ea348f9-1"
})(_templateObject1(), fonts.regular, getTypography("h4.bold"), breakpoints.tablet, breakpoints.desktop);
export var Description = styled.div.withConfig({
    componentId: "sc-3ea348f9-2"
})(_templateObject2(), fonts.regular, getTypography("h6.light"), breakpoints.tablet, breakpoints.desktop);
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-3ea348f9-3"
})(_templateObject3());
export var LoadingContainer = styled.div.withConfig({
    componentId: "sc-3ea348f9-4"
})(_templateObject4());
