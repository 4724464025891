import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  text-align: center;\n  padding: ",
        ";\n\n  @media (max-width: ",
        ") {\n    /* width:100vw; */\n  }\n\n  @media (min-width: ",
        ") {\n    /* width: 85.5vw; */\n  }\n\n  @media (min-width: ",
        ") {\n    width: 72vw;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  ",
        ";\n  font-weight: 800;\n  margin-top: 0;\n  margin-bottom: 8px;\n\n  @media (max-width: ",
        ") {\n    line-height: 36px;\n    font-size: 28px;\n    margin: 0;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  margin-top: 0;\n  font-size: 22px;\n  line-height: 32px;\n\n  @media (max-width: ",
        ") {\n    font-size: 20px;\n    line-height: 28px;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 48px;\n  font-weight: 400;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 24px;\n  font-weight: 700;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  font-weight: 400;\n  margin-bottom: 4px;\n  margin-top: 0;\n  font-size: 18px;\n  line-height: 26px;\n\n  @media (max-width: ",
        ") {\n    font-size: 16px;\n    line-height: 22px;\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 22px;\n  margin-bottom: 24px;\n  margin-top: 0;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  color: #008a00;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  color: #bd0000;\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  .otp {\n   ",
        ";\n    ",
        "\n  }\n "
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  @keyframes rotate {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n\n  @keyframes dash {\n    0% {\n      stroke-dasharray: 1, 150;\n      stroke-dashoffset: 0;\n    }\n\n    50% {\n      stroke-dasharray: 90, 150;\n      stroke-dashoffset: -35;\n    }\n\n    100% {\n      stroke-dasharray: 90, 150;\n      stroke-dashoffset: -124;\n    }\n  }\n\n  & svg {\n    animation: rotate 2s linear infinite;\n    z-index: 2;\n    width: 50px;\n    height: 50px;\n\n    & circle {\n      stroke: ",
        ";\n      stroke-linecap: round;\n      fill: trasparent;\n      animation: dash 1.5s ease-in-out infinite;\n    }\n  }\n "
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
import { breakpoints, colors, pxToCssFont, pxToRem } from "@vfit/shared/themes";
import styled from "styled-components";
export var MainContainer = styled.div.withConfig({
    componentId: "sc-40b9385-0"
})(_templateObject(), pxToRem(60, 60), breakpoints.mobile, breakpoints.tablet, breakpoints.desktop);
export var Title = styled.h4.withConfig({
    componentId: "sc-40b9385-1"
})(_templateObject1(), pxToCssFont(36, 48), breakpoints.desktop);
var H6 = styled.h6.withConfig({
    componentId: "sc-40b9385-2"
})(_templateObject2(), breakpoints.desktop);
export var StyledDescription = styled(H6).withConfig({
    componentId: "sc-40b9385-3"
})(_templateObject3());
export var TopDisclaimer = styled(H6).withConfig({
    componentId: "sc-40b9385-4"
})(_templateObject4());
export var CodeNotRecieved = styled.p.withConfig({
    componentId: "sc-40b9385-5"
})(_templateObject5(), breakpoints.desktop);
var ParagraphDisclaimer = styled.p.withConfig({
    componentId: "sc-40b9385-6"
})(_templateObject6());
export var NewCodeSentDisclaimer = styled(ParagraphDisclaimer).withConfig({
    componentId: "sc-40b9385-7"
})(_templateObject7());
export var ErrorOtp = styled(ParagraphDisclaimer).withConfig({
    componentId: "sc-40b9385-8"
})(_templateObject8());
export var OtpContainer = styled.div.withConfig({
    componentId: "sc-40b9385-9"
})(_templateObject9(), function(param) {
    var isError = param.isError;
    return isError ? "border-color: #bd0000" : "";
}, function(param) {
    var isError = param.isError;
    return isError ? "color: #bd0000" : "";
});
export var SVGSpinner = styled.div.withConfig({
    componentId: "sc-40b9385-10"
})(_templateObject10(), function(param) {
    var color = param.color;
    return color || "".concat(colors.$e60000);
});
