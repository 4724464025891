import { purify } from "@vfit/shared/themes";
export var organizeVoucherInsertion = function(cmsData) {
    var ref, ref1, ref2, ref3;
    var purifiedLabel = {
        purifiedPriceDetail: (ref = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : cmsData.priceDetails) || ""
        ])) === null || ref === void 0 ? void 0 : ref[0],
        purifiedDescription: (ref1 = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : cmsData.productDescription) || ""
        ])) === null || ref1 === void 0 ? void 0 : ref1[0],
        purifiedProductName: (ref3 = purify([
            (cmsData === null || cmsData === void 0 ? void 0 : (ref2 = cmsData.productName) === null || ref2 === void 0 ? void 0 : ref2.toUpperCase()) || ""
        ])) === null || ref3 === void 0 ? void 0 : ref3[0]
    };
    return purifiedLabel;
};
